import { ADD_CART, REMOVE_CART, CHANGE_PRODUCT_QTY, EMPTY_CART } from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  cart: [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CART:
      return { ...state, cart: [...state.cart, action.payload] };
    case REMOVE_CART:
      return { ...state, cart: state.cart.filter((item) => item.id !== action.payload) };
    case EMPTY_CART:
      return { ...state, cart: [] };
    case CHANGE_PRODUCT_QTY:
      state.cart.forEach((product) => {
        if (product.id === action.payload.id) {
          product = action.payload;
        }
      });
      return { ...state, cart: [...state.cart] };

    default:
      return state;
  }
};
const persistConfig = {
  keyPrefix: "molla-",
  key: "cart",
  storage,
};

export default persistReducer(persistConfig, cartReducer);
