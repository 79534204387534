import React from "react";
import "./credit-card.scss";
import chip from "./images/credit-card-chip.png";

const CreditCard = ({ value, rotateCard }) => {
  let numberVal = value.number.split("-").join("");
  const cardNumber = value.number ? numberVal.match(/.{1,4}/g).join("-") : "**** **** **** ****";
  const cardName = value.name ? value.name : "YOUR NAME";
  const cardMonth = value.month ? value.month : "mm";
  const cardYear = value.year ? value.year : "yyyy";
  const cardCvv = value.cvv ? value.cvv : "CVV";

  return (
    <div className={`credit-card-container ${rotateCard && "credit-card-container-rotate"}`}>
      <div className="credit-card">
        <div className="front d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-between align-items-center">
            <img src={chip} alt="chip" />
            <h6>Your Card</h6>
          </div>
          <h6 className="lead text-center">{cardNumber}</h6>
          <div className="d-flex justify-content-between align-items-center flex-1">
            <p>
              <b>{cardName}</b>
            </p>
            <div>
              <span>Valid through</span>
              <p className="text-center">
                <b>
                  {cardMonth} / {cardYear}
                </b>
              </p>
            </div>
          </div>
        </div>
        <div className="back d-flex flex-column align-items-center">
          <div className="black-stripe"></div>
          <div className="white-stripe">
            <p className="text-right">
              <b>{cardCvv}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
