import React from "react";
import ButtonPrimary from "../../ui/button/ButtonPrimary";
import product from "./images/product.png";
import "./style.scss";

const WarrantySection = () => {
  return (
    <div className="warranty-section">
      <div className="img">
        <img src={product} alt="product" />
      </div>

      <div className="content">
        <h3>KanexPro Warranty Statement</h3>
        <p>KanexPro – 3 Year Limited Warranty Policy</p>
        <ButtonPrimary text="Learn More" link="/warranty" />
      </div>
    </div>
  );
};

export default WarrantySection;
