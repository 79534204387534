import React, { useEffect } from "react";
import WarImg from "./warranty.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function WarrantyPage() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="">
      <div className="WarDiv1 justify-content-center">
        <div className="">
          <br></br>
          <div className="d-flex inWarDiv1">
            <p className="lead inWarDiv1">KanexPro Warranty Statement – Effective September 8, 2015*</p>
            <br></br>
            <p className="lead inWarDiv1">KanexPro – 3 Year Limited Warranty Policy</p>
            <br></br>
            <img className="img-responsive WarImg" src={WarImg} alt="warranty" />
          </div>
        </div>
      </div>

      <div className="info-container">
        <div className="section">
          <h1 className="display-5">Coverage</h1>
          <p>
            * KanexPro Products must be acquired from an Authorized KanexPro reseller and purchased past September 1,
            2015 in order to qualify for our three-year warranty.
          </p>
          <p>
            KanexPro warrants its products will greatly perform to their published specifications and will be free from
            defects in materials and workmanship under normal use, conditions and service for up to three years.
          </p>
          <p>Under its Limited Product Warranty, KanexPro, at its sole discretion, will either:</p>
          <p>
            1. Repair or facilitate the repair of defective products within a reasonable period of time, restore
            products to their proper operating condition and return defective products free of any charge for necessary
            parts, labor and shipping
          </p>
          <p className="text-center font-weight-bold">OR</p>
          <p>
            2. Replace and return, free of charge, any defective products with direct replacement or with similar
            products deemed by KanexPro to perform substantially the same function as the original products
          </p>
          <p>
            Repair, replacement or refund of KanexPro products is the purchaser’s exclusive remedy and KanexPro
            liability does not extend to any other damages, incidental, consequential or otherwise.
          </p>
          <p>
            This Limited Product Warranty extends to the original end-user purchaser of KanexPro products and is
            non-transferrable to any subsequent purchaser(s) or owner(s) of these products.
          </p>
        </div>

        <div className="section">
          <h1 className="display-5">Coverage Times</h1>
          <p>
            KanexPro Limited Product Warranty Period begins on the date of purchase by the end-purchaser. The date
            contained on the end purchaser‘s sales or delivery receipt is the proof purchase date. Limited Product
            Warranty Terms
          </p>
          <ul>
            <li>3-years from proof of purchase date for hardware/electronics products</li>
            <li>
              90-days - Limited Product Warranty Terms – Refurbished (B-Stock) Products from the date of purchase.
            </li>
          </ul>
        </div>

        <div className="section">
          <h1 className="display-5">Remedy</h1>
          <p>
            KanexPro recommends that end-purchasers contact their authorized KanexPro dealer or reseller from whom they
            purchased their products or they can also contact KanexPro directly at www.kanexpro.com for contact
            information and hours of operation. KanexPro requires that a dated sales or delivery receipt from an
            authorized dealer, reseller or end-purchaser is provided before KanexPro extends its warranty services.
          </p>
          <p>
            Additionally, a return merchandise authorization (RMA) and/or case number is required to be obtained from
            KanexPro in advance of returns.
          </p>
          <p>
            KanexPro requires that products returned be properly packed, preferably in the original carton, for
            shipping. Cartons/ Boxes not bearing a return authorization or case number will be refused. KanexPro, at its
            sole discretion, reserves the right to reject any products received without advanced authorization.
            Authorizations can be requested by calling 1-888-975-1368 (US toll free) or 1-714-332-1681 for
            (US/international).
          </p>
        </div>

        <div className="section">
          <h1 className="display-5">Exclusions</h1>
          <p>This Limited Product Warranty excludes:</p>
          <ul>
            <li>
              Damage, deterioration or malfunction caused by any alteration, modification, improper use, neglect,
              improper packing or shipping (such claims must be presented to the carrier), lightning, power surges, or
              other acts of nature.
            </li>
            <li>
              Damage, deterioration or malfunction resulting from the installation or removal of this product from any
              installation, any unauthorized tampering with this product, any repairs attempted by anyone unauthorized
              by KanexPro to make such repairs, or any other cause which does not relate directly to a defect in
              materials and/or workmanship of this product.
            </li>
            <li>
              Equipment enclosures, cables, power supplies, batteries, LCD displays, and any accessories used in
              conjunction with the product(s).
            </li>
            <li>
              Products purchased from unauthorized distributors, dealers, resellers, auction websites and similar
              unauthorized channels of distribution.
            </li>
          </ul>
        </div>

        <div className="section">
          <h1 className="display-5">Disclaimers</h1>
          <p>
            This Limited Product Warranty does not entail that the electronic components contained within KanexPro
            products will not become obsolete nor does it imply KanexPro products or their electronic components will
            remain compatible with any other current product, technology or any future products or technologies in which
            KanexPro products may be used in conjunction with. KanexPro, at its sole discretion, reserves the right not
            to extend its warranty offering in instances arising outside its normal course of business including, but
            not limited to, damage inflicted to its products from acts of god.
          </p>
        </div>

        <div className="section">
          <h1 className="display-5">Limitation on Liability</h1>
          <p>
            The maximum liability of KanexPro under this limited product warranty shall not exceed the original KanexPro
            MSRP for its products. To the maximum extent permitted by law, KanexPro is not responsible for the direct,
            special, incidental or consequential damages resulting from any breach of warranty or condition, or under
            any other legal theory. Some countries, districts or states do not allow the exclusion or limitation of
            relief, special, incidental, consequential or indirect damages, or the limitation of liability to specified
            amounts, so the above limitations or exclusions may not apply to you.
          </p>
        </div>

        <div className="section">
          <h1 className="display-5">Exclusive Remedy</h1>
          <p>
            To the maximum extent permitted by law, this limited product warranty and the remedies set forth above are
            exclusive and in lieu of all other warranties, remedies and conditions, whether oral or written, express or
            implied. To the maximum extent permitted by law, KanexPro specifically disclaims all implied warranties,
            including, without limitation, warranties of merchantability and fitness for a particular purpose. If
            KanexPro cannot lawfully disclaim or exclude implied warranties under applicable law, then all implied
            warranties covering its products including warranties of merchantability and fitness for a particular
            purpose, shall provide to its products under applicable law. If any product to which this limited warranty
            applies is a “Consumer Product” under the Magnuson-Moss Warranty Act (15 U.S.C.A. §2301, ET SEQ.) or other
            applicable law, the foregoing disclaimer of implied warranties shall not apply, and all implied warranties
            on its products, including warranties of merchantability and fitness for the particular purpose, shall apply
            as provided under applicable law.
          </p>
        </div>

        <div className="section">
          <h1 className="display-5">Other Conditions</h1>
          <p>
            KanexPro Limited Product Warranty offering gives legal rights, and other rights may apply and vary from
            country to country or state to state. This limited warranty is void if (i) the label bearing the serial
            number of products have been removed or defaced, (ii) products are not purchased from an authorized KanexPro
            dealer or reseller.
          </p>
        </div>

        <div className="section">
          <h1 className="display-5">Shipping RMA’s to and from KanexPro</h1>
          <p>
            KanexPro will provide free one-way shipping returning the product back to customer; however, the customer is
            entitled to pay for shipping, sending the defective product back to KanexPro. Free ground shipping for
            pre-qualified customer distributors in U.S. Customers may opt to use their own shipping company to expedited
            the shipping method if the replacement is critically needed.
          </p>
        </div>

        <div className="section">
          <h1 className="display-5">RMA’s for International Customers</h1>
          <p>
            KanexPro will provide free one-way shipping returning the product back to customer; however, the customer is
            entitled to pay for shipping, custom duties or any applicable tax charges based on their respective
            countries when sending the defective product back to KanexPro.
          </p>
        </div>
        <hr />
        <div className="section text-center">
          <span className="d-block mb-2">All rights reserved {new Date().getFullYear()}.</span>
          <span className="d-block">4295 East Jurupa Street #111, Ontario, CA 91761</span>
          <span className="d-block">United States of America</span>
        </div>
      </div>
    </div>
  );
}
