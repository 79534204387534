export const productsData = {
  cables: {
    title: "AV Cables Manufacture | Audio Video Wiring | AV Systems Wires",
    description:
      "KanexPro is the leading Audio Video Wiring and Cable Manufacturer. We specialize in AV systems wires. Check out the best one-stop-shop for Audio Video equipment.",
    keywords: "kanexpro,AOC Cabling,HDMI Cabling,HDMI Certified Cabling,USB Cabling",

    aoc: {
      name: "Active Optical Cables (AOC)",
      title: "Active Optical Cables | AOC Cables | Active Fiber Optics Cables",
      description:
        "KanexPro is the leading Active Optical Cables Manufacturer. We specialize in AOC cables. Check out the best one-stop-shop for Fiber Optics and HDMI Cables.",
      keywords:
        "kanexpro,CBL-HDMIAOC20M,CBL-HDMIAOC30M,CBL-HDMIAOC50M,CBL-DP14AOC20M,CBL-DP14AOC30M,CBL-DP14AOC50M,CBL-AOC100M4K,CBL-AOC30M4K,CBL-AOC50M4K",
    },
    hdmi: {
      name: "HDMI Cables",
      title: "HDMI Cables & Wires Manufacturer | High-Speed Certified HDMI Wiring",
      description:
        "KanexPro is the leading HDMI Cables & Wires Manufacturer. We carry high-speed certified HDMI cables. Check out the best one-stop-shop HDMI Wiring and Cables.",
      keywords:
        "kanexpro,CBL-HT8181HDMI25FT,CBL-HT8181HDMI50FT,CBL-HT7180HDMI75FT,CBL-HT7180HDMI100FT,HDLK3FT,HD25FTCL314,HD50FTCL314,HD75FTCL314,HD100FTCL314",
    },
    hdmicertified: {
      name: "Premium High Speed HDMI",
      title: "KanexPro Premium High Speed HDMI",
      description:
        "Premium High Speed HDMI® Cables are designed to meet and exceed the highest standards of HDMI performance. Tested and certified by HDMI Authorized Test Centers. Supports all features in the latest HDMI specifications. 18Gbps guarantees performance for all modern 4K equipment. ",
      keywords: "kanexpro,CBL-HDMICERTSS3FT,CBL-HDMICERTSS6FT,CBL-HDMICERT15FT,CBL-HDMICERT25FT",
    },
    usb: {
      name: "USB",
      title: "KanexPro USB",
      description: "Long distance USB extension over SuperSpeed USB 3.0",
      keywords: "kanexpro,EXT-USB16FT,EXT-USB32FT",
    },
  },
  control: {
    title: "KanexPro control",
    description: "Professional Audio Video components",
    keywords: "kanexpro,Extenders control",

    extenders: {
      name: "System Control Devices",
      title: "KanexPro System Control Devices",
      description:
        "To control projection screens, AV switching and relays via RS-232 or Ethernet are always critical in any Pro AV applications. Check out our latest control products. ",
      keywords: "kanexpro,WP-IPKEYPAD8,CR-3XCONTROL ,WP-CONTROLB,WP-CONTROLS",
    },
  },
  converters: {
    title: "AV Converters | Audio Video Converters | AV Systems Converters",
    description:
      "KanexPro is the leading AV converters Manufacturer. We carry top-of-line Audio Video Converters. Check out our other AV Systems Solutions equipment and products.",
    keywords:
      "kanexpro,Audio Converters,Component Converters,Composite Converters,DVI Converters,HDMI Converters,SDI Converters,VGA Converters",

    audio: {
      name: "Audio Converters",
      title: "Digital to Analog Converters | Audio Converters | Audio Amplifiers",
      description:
        "KanexPro is the leading AV Audio converters Manufacturer. We carry top-of-line digital to audio converters and audio amplifiers. Check out our other AV products.",
      keywords: "kanexpro,HAECOAX4 ,HAECOAX3,AP3DBL,AP2DBL,HAECOAX,AUA2DCV,AUD2ACV",
    },
    component: {
      name: "Component Converters",
      title: "HDMI to Component Video with Audio Converter | Component Converters",
      description:
        "KanexPro is the leading AV components converters manufacturer. We carry top-of-line products like HDMI to Components video with audio converters. Learn more here.",
      keywords: "kanexpro,HDRGBRL",
    },
    composite: {
      name: "Composite Converters",
      title: "Composite/S-Video to 4K HDMI | CON-AV-HD4k Universal Scaler Converter",
      description:
        "KanexPro is the leading AV composite converters manufacturer. We carry top-of-line composite converters and universal scaler converters. Learn more here.",
      keywords: "kanexpro,CON-AV-HD4K,HDRCA,HDCVRYW",
    },
    dvi: {
      name: "DVI Converters",
      title: "DVI Converters | DVI with RCA Audio to HDMI Converter | Full HD 1080p",
      description:
        "KanexPro is the leading DVI converters manufacturer. We carry top-of-line DVI with RCA audio to HDMI Converters (Full HD 1080p). For more detail click here.",
      keywords: "kanexpro,DVIRLHD",
    },
    hdmi: {
      name: "HDMI Converters",
      title: "HDMI Converter | Audio Extractor with Dolby Atmos | Audio De-Embedder",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line HDMI converters, audio extractors, and audio de-embedders. For more details click here.",
      keywords: "kanexpro,CON-GAMECAPL,CON-GAMECAP",
    },
    sdi: {
      name: "SDI Converters",
      title: "SDI Converters | SDI to VGA Converter | 3G-SDI to HDMI Converter",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line SDI to VGA converters and 3G-SDI to HDMI converters. For more details visit our website.",
      keywords: "kanexpro,EXT-SDIVGA,EXT-SDHDX,EXT-HDSDIX",
    },
    vga: {
      name: "VGA Converters",
      title: "VGA Converter | VGA to HDMI Video Converter | Audio to HDMI Converter ",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line VGA converters, Audio to HDMI converters, and VGA to HDMI converters. See more here.",
      keywords: "kanexpro,CON-VGA-HD4K,VGARLHD,HDVGARL",
    },
  },
  extenders: {
    title: "AV Extenders | Audio & Video Extenders | AV Senders & Receivers",
    description:
      "KanexPro is the leading AV systems manufacturer. We carry top-of-line Audio & Video extenders. We also have AV Senders and Receivers. Visit our site for more info.",
    keywords:
      "kanexpro,Fiber Optic Extenders,HDBaseT Extenders,HDMI Extenders,USB Extenders,VGA Extenders,Wall Plates Extenders",

    fiberoptic: {
      name: "Fiber Optic",
      title:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line HDMI fiber optic extenders and fiber optic SDI extender sets. For more details, click here.HDMI Fiber Optic Extenders | Fiber Optic SDI Extender (HD 1080p/60)",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line HDMI fiber optic extenders and fiber optic SDI extender sets. For more details, click here.",
      keywords: "kanexpro,EXT-4KFIBERPRO,SDI-EXTFIBERPRO",
    },
    hdbaset: {
      name: "HDBaseT Extenders",
      title: "HDMI Extender over HdbaseT | 4k/30 HDMI Transmitter and Receiver",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line HDMI extenders over HDbaseT and HDMI transmitters and receivers. Click here to learn more.",
      keywords:
        "kanexpro,EXT-HDBT70C,EXT-HDBT150M,EXT-100SL18G,EXT-70SL18G,EXT-HDBT70M,EXT-HDBT70MTX,EXT-HDBT70MRX,EXT-HDBTKVM100,EXT-HD100MHBT,HDBASE100ME",
    },
    hdmi: {
      name: "HDMI Extenders",
      title: "KanexPro HDMI Extenders",
      description:
        "HDMI extenders work as transmitter and receiver pairs that facilitate long distance transmission of digital signals via shielded or unshielded CAT5e/6/7 cables.",
      keywords: "kanexpro,EXT-HD50C,EXT-50M18G,EXT-18GHDMIWL,EXT-HD60M,HDEXT50M,HDREPEAT10G",
    },
    usb: {
      name: "USB Extenders",
      title: "KanexPro USB Extenders",
      description:
        "USB extenders work as transmitter and receiver pairs that facilitate long distance transmission of USB via shielded or unshielded CAT5e/6/7 cables.",
      keywords: "kanexpro,EXT-USB2100M,EXT-USB250M",
    },
    vga: {
      name: "VGA Extenders",
      title: "VGA Extenders | Extend High-Resolution VGA signals | VGA 1x1 Extender",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line VGA Extenders. Visit our website for more Audio-Video systems equipment and information.",
      keywords: "kanexpro,VGAEXTX1,VGAEXTX2,VGAEXTX4",
    },
    wallplates: {
      name: "Wall Plates",
      title: "HDMI Wall Plate Switcher & Transmitter | Extend & Control AV signals",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line HDMI Wall Plate Switcher & Transmitter. Visit our site for more AV Systems equipment.",
      keywords: "kanexpro,WP-EDUKIT4K,WP-2X1HDUSBC,WP-HDBTKVM3,WP-EXTHDBTKIT,WP-HDBASETX",
    },
  },
  networkav: {
    title: "KanexPro NetworkAV",
    description: "Professional Audio Video components",
    keywords: "kanexpro,H.264 NetworkAV,SDVoE NetworkAV",

    h264: {
      name: "H.264",
      title: "KanexPro H.264",
      description:
        "1G ethernet based AV distribution system powered by H.264 video compression. H.264 systems are older and more affordable than H.265 and SDVoE solutions. These solutions are stable and can be integrated into any 1G network environment.",
      keywords: "kanexpro,EXT-NETAVTX,EXT-NETAVRX,EXT-AVIPH264TX,EXT-AVIPH264RX,EXT-AVIP120M,EXT-AVIP120MRX",
    },
    sdvoe: {
      name: "SDVoE",
      title: "KanexPro SDVoE",
      description:
        "SDVoE delivers the capability to send 4K/60 video over 10G Ethernet networks. Zero Latency and Instant switching make these systems ideal for the most state of the art installations.",
      keywords: "kanexpro,EXT-PROMATRIXUSB,EXT-PROCTRL,EXT-PRORACK",
    },
  },
  sdi: {
    title: "SDI Cables (Serial Digital Interface Cables) | HD-SDI Video Cables",
    description:
      "KanexPro is the leading AV systems manufacturer. We carry top-of-line SDI Cables (Serial Digital Interface cables and HD-SDI video cables. See more on our website.",
    keywords: "kanexpro,Converters SDI,Extenders SDI,Splitters SDI",

    converters: {
      name: "Converters",
      title: "SDI Converters | SDI to HDMI Converter | SDI/HDMI Audio Embedder",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line SDI Converters and SDI/HDMI audio embedders. For more AV products, please visit our website.",
      keywords: "kanexpro,SDI-SDHDXPRO,SDI-HDSDXPRO,SDI-SDI2MULTIPRO,SDI-MULTI2SDIPRO",
    },
    extenders: {
      name: "Extenders",
      title: "SDI Extenders | Fiber Optic SDI Extender Set | HD-SDI Repeater",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line SDI Extenders and HD-SDI Repeaters. For more AV products, feel free to visit our website.",
      keywords: "kanexpro,SDI-EXTFIBERPRO,SDI-HDRPTPRO",
    },
    splitters: {
      name: "Splitters",
      title: "SDI Splitters | HD-SDI Splitters for SDI | 3G-SDI |SD-SDI Video",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line SDI Splitter and HD-SDI splitters. For more AV products, feel free to visit our website.",
      keywords: "kanexpro,SP-SDIX2,SP-SDIX4",
    },
  },
  splitters: {
    title: "HDMI Splitter | 4K HDMI 2-Port Video Splitter | 4-Port HDMI Splitter",
    description:
      "KanexPro is the leading AV systems manufacturer. We carry top-of-line HDMI Splitters such as 2-port video splitters and 4-port HDMI Splitters. See more on our site.",
    keywords: "kanexpro,HDMI Splitters,HDMI Professional Splitters",

    hdmi: {
      name: "HDMI Splitters",
      title: "KanexPro HDMI Splitters",
      description: "Distribute HDMI signals into two or more identical copies of the original signals.",
      keywords:
        "kanexpro,SP-HDPOC1X4,SP-HDPOC1X8,SP-HDBT1X4KIT,SP-HDCAT1X8,SP-HDCAT1X4,SP-HD20-1X44K,SP-HD20-1X24K,SP-HD1X44K,SP-HD1X24K",
    },
    hdmiprofessional: {
      name: "HDMI Professional Splitters",
      title: "KanexPro HDMI Professional Splitters",
      description:
        "High-end HDCP-compliant distribution amplifiers engineered for reliable operation in commercial AV applications supporting up to 4K resolutions.",
      keywords: "kanexpro,SP-1X4SL18G,SP-1X2SL18G,HDSP184K,HDSP164K,SP-HDBT1X4",
    },
  },
  switchers: {
    title: "Audio Video Switchers | Matrix Switchers | AV Scalers & Switchers",
    description:
      "KanexPro is the leading AV systems manufacturer. We carry top-of-line Audio-Video Switchers and Audi-Video Scalers. Visit our site for more AV Systems products.",
    keywords:
      "kanexpro,HDMI Switchers,KVM Switchers,Matrix Switchers,Matrix Modular Switchers,Matrix Modules Switchers,Scaler Switchers",

    hdmi: {
      name: "HDMI Switchers",
      title: "HDMI Switcher with 4k Support | For Multiple HDMI 4k UHD Switching",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line HDMI Switchers with 4k support for multiple HDMI 4k UHD switching. Learn more at our website.",
      keywords: "kanexpro,SW-2X14KUSBC,SW-4X1SL18G,SW-HD20-3X14K,SW-HD20-5X14K,SW-HD3X14K,SW-HD5X14K,SW-HD4X1AUD4K",
    },
    kvm: {
      name: "Professional KVM Products",
      title: "KVM Switches | 4k Quad Multiview 4x1 KVM Switch | USB KVM Switch",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line KVM Switches such as 4k Quad Multiview 4x1 KVM Switch. See more AV products on our website.",
      keywords: "kanexpro,SW-HDSC4X1KVM,SW-4X1KVMMV",
    },
    matrix: {
      name: "Matrix Switchers",
      title: "Matrices and Matrix Switchers | Multi-Format HDMI Matrix Switchers",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line Matrices and Multi-Format HDMI Matrix Switchers. See more AV products on our website.",
      keywords:
        "kanexpro,HDMX42A-18G,HDMX44A-18G,HDMX88A-18G,MX-HDBT8X818G,HDMX42-18G,SW-HDMX44CE,HDMX44-18G,HDMX88-18G",
    },
    matrixmodular: {
      name: "4K Matrix Modular",
      title: "KanexPro 4K Matrix Modular ",
      description:
        "Field-upgradeable, modular and hot-swappable - digital and analog matrix switcher’s ideal for multi-switching applications requiring future expandability",
      keywords: "kanexpro,FLEX-MMX32,FLEX-MMX16,HDMMX1616-4K,HDMMX3232-4K,HDMMX6464-4K",
    },
    matrixmodules: {
      name: "Matrix Modules",
      title: "Modular Matrix Switch | Modular Matrix Chassis and Modular Matrices",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line Modular Matrix Switches and Modular Matrices. For more AV products, please visit our website.",
      keywords:
        "kanexpro,FLEX-IN-VGA,FLEX-OUT-VGA,FLEX-IN-SDI,FLEX-IN-DVI,FLEX-OUT-DVI,FLEX-IN-HD4K,FLEX-OUT-HD4K,FLEX-IN-HDBT4K,FLEX-OUT-HDBT4K,FLEX-IN-HD,FLEX-OUT-HD,FLEX-IN-HDBT,FLEX-OUT-HDBT,MOD-IN-CAT6-4K,MOD-OUT-CAT6-4K,MOD-IN-HDTV-4K,MOD-OUT-HDTV-4K,MOD-IN-FIBER,MOD-OUT-FIBER,MOD-OT-VGA,MOD-OT-DVI,MOD-IN-DVI,MOD-IN-VGA,MOD-IN-SDI,MOD-OT-SDI,MOD-IN-FIBER,MOD-OT-FIBER",
    },
    scaler: {
      name: "Scaler",
      title: "AV Scaler & Switcher | HDMI Scaler Switcher | AV Switch and Scale",
      description:
        "KanexPro is the leading AV systems manufacturer. We carry top-of-line AV Scalers, AV Switchers, and HDMI Scaler Switchers. Visit our site for more AV products.",
      keywords:
        "kanexpro,SW-HDSC42D4K,HDSC31D-4K,SW-HDSC51HDBT ,HDSC61D-4K,VW-SP1X44K,HDSC71D-4K,SW-HDSC914K,HD-VTSC72-4K,HDSC51HDBT",
    },
  },
};
