import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "../../../ui/button/ButtonPrimary";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import { account } from "../../common/partials/navData";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import InputPasswordPrimary from "../../../ui/input/InputPasswordPrimary";
import "./change-password.scss";
import { noSpaceOnChange, notEmptyValidation, passwordValidation } from "../../../validation/formValidation";
import { connect } from "react-redux";
import { formStatusCustom, formStatusNull, formStatusPending } from "../../../actions/forms";
import axios from "../../../api";
import { changePassword } from "../../../actions/auth";

const ChangePassword = ({
  location,
  history,
  auth,
  formStatusCustom,
  formStatusNull,
  formStatusPending,
  changePassword,
}) => {
  if (!auth.isAuthenticated) {
    history.push("/");
  }

  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [passwordError, setPasswordError] = useState({
    oldPasswordError: "",
    newPasswordError: "",
    confirmNewPasswordError: "",
  });
  const [formError, setFormError] = useState("");

  const onChangePassword = (e) => {
    setFormError("");
    if (e.target.name === "newPassword") {
      setPasswordError({
        ...passwordError,
        newPasswordError: "",
      });
      noSpaceOnChange(e.target.value) && setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
    } else if (e.target.name === "confirmNewPassword") {
      setPasswordError({
        ...passwordError,
        confirmNewPasswordError: "",
      });
      noSpaceOnChange(e.target.value) && setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
    } else {
      setPasswordError({
        ...passwordError,
        oldPasswordError: "",
      });
      noSpaceOnChange(e.target.value) && setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
    }
  };

  const onBlurPassword = (e) => {
    if (e.target.name === "oldPassword" && !notEmptyValidation(passwordData.oldPassword)) {
      setPasswordError({
        ...passwordError,
        oldPasswordError: "Please enter your old password",
      });
    }
    if (e.target.name === "newPassword" && !passwordValidation(passwordData.newPassword)) {
      setPasswordError({
        ...passwordError,
        newPasswordError: "Your password must have at least 6 characters, an uppercase, a lowercase and a number",
      });
    }
    if (e.target.name === "confirmNewPassword" && passwordData.newPassword !== passwordData.confirmNewPassword) {
      setPasswordError({
        ...passwordError,
        confirmNewPasswordError: "Entered password must match the new password",
      });
    }
  };

  const onSubmitValidation = () => {
    if (
      !notEmptyValidation(passwordData.oldPassword) ||
      !passwordValidation(passwordData.newPassword) ||
      passwordData.newPassword !== passwordData.confirmNewPassword
    ) {
      setFormError("Please enter valid credentials!");
      return false;
    } else {
      return true;
    }
  };

  const onSubmitPassword = (e) => {
    e.preventDefault();

    if (!onSubmitValidation()) {
      return;
    }
    formStatusPending();

    try {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, { action: "submit" });

        const res = await axios.get("/api/Home/ValidateCaptcha", {
          params: {
            captcha: token,
          },
        });

        if (res.data) {
          await changePassword(passwordData.oldPassword, passwordData.newPassword, passwordData.confirmNewPassword);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        } else {
          formStatusCustom(404);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        }
      });
    } catch (error) {
      formStatusCustom(404);
      setTimeout(() => {
        formStatusNull();
      }, 3000);
    }
  };
  return (
    <>
      <Helmet>
        <title>Change Password</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={account} title="Account" />
        </div>
        <div className="change-password">
          <h1 className="display-4 mb-3">Change Password</h1>
          <ListFreePrimary customClass="reset-password-form">
            <form className="faq-form" onSubmit={(e) => e.preventDefault()}>
              {/* <h1 className="display-center-5 fs-s text-center">Change your password</h1> */}
              <p className="mb-2">
                Your new password must have minimum 6 characters, one uppercase, one lowercase and one number.
              </p>

              <div className="form-group">
                <InputPasswordPrimary
                  id="inputOldPassword"
                  placeholder="Enter your old password"
                  name="oldPassword"
                  value={passwordData.oldPassword}
                  onChange={onChangePassword}
                  onBlur={onBlurPassword}
                />
                {passwordError.oldPasswordError && (
                  <p className="invalid-data mt-1">{passwordError.oldPasswordError}</p>
                )}
              </div>
              <div className="form-group">
                <InputPasswordPrimary
                  id="inputNewPassword"
                  placeholder="Enter your new password"
                  name="newPassword"
                  value={passwordData.newPassword}
                  onChange={onChangePassword}
                  onBlur={onBlurPassword}
                />

                {passwordError.newPasswordError && (
                  <p className="invalid-data mt-1">{passwordError.newPasswordError}</p>
                )}
              </div>
              <div className="form-group">
                <InputPasswordPrimary
                  id="inputConfirmNewPassword"
                  placeholder="Confirm your new password"
                  name="confirmNewPassword"
                  value={passwordData.confirmNewPassword}
                  onChange={onChangePassword}
                  onBlur={onBlurPassword}
                />
                {passwordError.confirmNewPasswordError && (
                  <p className="invalid-data mt-1">{passwordError.confirmNewPasswordError}</p>
                )}
              </div>
              {formError && (
                <p className="invalid-data mt-1 tc">
                  <i className="fas fa-exclamation-triangle mr-2"></i>
                  {formError}
                </p>
              )}
              <div className="d-flex justify-content-around mt-3">
                <ButtonPrimary onClick={() => history.goBack()} text="Cancel" />
                <button size={1} type="submit" className="btn btn-secondary">
                  Submit
                </button>
              </div>
            </form>
          </ListFreePrimary>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { formStatusCustom, formStatusNull, formStatusPending, changePassword })(
  ChangePassword
);
