import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./NewsAccordion.scss";
import Aos from 'aos'
 function NewsAccordion  ({item})  {
  
  return (
    <div className="Newsaccordion-primary-tabs"  style={{marginTop:'40px'}}>
      <div className="Newsaccordion-primary-tab">
        <input className="Newsaccordion-primary-input" type="checkbox" checked="true" id={item.id} />
        <label className="Newsaccordion-primary-tab-label" for={item.id}>
        <Link className="NewsTitle" to='/media/article/:articleid'>#{item.id}. {item.title}</Link> 
        </label>
        <hr />
        <div class="Newsaccordion-primary-tab-content">{item.description}</div>
      </div>
    </div>
  );
};

export default NewsAccordion;
