import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getShipmentDetails, setAccountLoadingTrue } from "../../../actions/account";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import { account } from "../../common/partials/navData";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import "./order-details.scss";
import { Link } from "react-router-dom";
import InputSearchSecondary from "../../../ui/input/InputSearchSecondary";
import LoaderPrimary from "../../../ui/loader/LoaderPrimary";

const OrderDetails = (props) => {
  const { location, match, history, auth } = props;

  if (!auth.isAuthenticated) {
    history.push("/");
  }

  const { getShipmentDetails, setAccountLoadingTrue, table1, table2, table3, accountLoading } = props;

  useEffect(() => {
    getShipmentDetails(match.params.orderId, "C100578");

    return () => {
      setAccountLoadingTrue();
    };
  }, []);

  const scrollRef = useRef();
  const scrollRef2 = useRef();
  const scrollTable = (direction, tableScrollRef) => {
    if (direction === "left") {
      tableScrollRef.current.scrollLeft -= 100;
    }
    if (direction === "right") {
      tableScrollRef.current.scrollLeft += 100;
    }
  };

  const scrollTable2 = (direction, tableScrollRef) => {
    if (direction === "left") {
      tableScrollRef.current.scrollLeft -= 100;
    }
    if (direction === "right") {
      tableScrollRef.current.scrollLeft += 100;
    }
  };

  const renderTable1 = () => {
    return table1.map((item, index) => (
      <tr key={index}>
        <td>
          <Link to={`/item/${item.ItemCode}`}>{item.ItemCode}</Link>
        </td>
        <td>{item.Dscription}</td>
        <td>{item.Qty}</td>
        <td>{item.Open}</td>
        <td>{item.whscode}</td>
        <td>{item.Price}</td>
        <td>{item.Total}</td>
      </tr>
    ));
  };

  const renderTable3 = () => {
    return table3.map((item, index) => (
      <tr key={index}>
        <td>{item.docdate ? item.docdate : "N/A"}</td>
        <td>{item.TrackNo ? item.TrackNo : "N/A"}</td>
        <td>{item.Invoice ? item.Invoice : "N/A"}</td>
      </tr>
    ));
  };

  if (accountLoading) {
    return <LoaderPrimary />;
  }

  return (
    <div className="d-flex">
      <div className="d-none d-lg-block d-xl-block">
        <SidebarSecondary path={location.pathname} data={account} title="Account" />
      </div>

      <div className="order-details">
        <h1 className="display-4">Order Details</h1>
        <p className="lead mb-1">
          <b>Order number: </b>#{match.params.orderId}
        </p>
        <div className="bill-ship-container mb-3">
          <ListFreePrimary customClass="bill">
            <p className="lead fs-m">Bill to</p>
            <hr />

            <p>
              <b>To: </b>
              {table2[0] ? table2[0].CardName : "N/A"}
            </p>
            <p>
              <b>Address: </b>
              {table2[0] ? table2[0].Address : "N/A"}
            </p>
            <p>
              <b>PO: </b>
              {table2[0] ? table2[0].po : "N/A"}
            </p>
          </ListFreePrimary>
          <ListFreePrimary customClass="ship">
            <p className="lead fs-m">Ship to</p>
            <hr />
            <p>
              <b>Address: </b>
              {table2[0] ? table2[0].Address2 : "N/A"}
            </p>
          </ListFreePrimary>
        </div>
        {table1.length !== 0 && (
          <ListFreePrimary customClass="mb-3">
            <p className="lead fs-m">Items</p>
            <hr />
            {/* <InputSearchSecondary customClass="mb-2" /> */}
            <span>
              <span className="scroll-btn" onClick={() => scrollTable("left", scrollRef)}>
                ❮
              </span>{" "}
              Scroll{" "}
              <span className="scroll-btn" onClick={() => scrollTable("right", scrollRef)}>
                ❯
              </span>
            </span>

            <div ref={scrollRef} className="scroll-x">
              <table className="table">
                <thead className="row-brown">
                  <tr>
                    <th scope="col">Sku</th>
                    <th scope="col">Descriptiion</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Open</th>
                    <th scope="col">Ship From</th>
                    <th scope="col">Price</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>

                <tbody>{renderTable1()}</tbody>
              </table>
            </div>
          </ListFreePrimary>
        )}

        {table3 && table3.length !== 0 && (
          <ListFreePrimary>
            <p className="lead fs-m">Info</p>
            <hr />
            {/* <InputSearchSecondary customClass="mb-2" /> */}
            <span>
              <span className="scroll-btn" onClick={() => scrollTable2("left", scrollRef2)}>
                ❮
              </span>{" "}
              Scroll{" "}
              <span className="scroll-btn" onClick={() => scrollTable2("right", scrollRef2)}>
                ❯
              </span>
            </span>

            <div ref={scrollRef2} className="scroll-x">
              <table className="table">
                <thead className="row-brown">
                  <tr>
                    <th scope="col">Shipmet Date</th>
                    <th scope="col">Tracking</th>
                    <th scope="col">Invoice</th>
                  </tr>
                </thead>

                <tbody>{renderTable3()}</tbody>
              </table>
            </div>
          </ListFreePrimary>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    table1: state.account.shipmentDetails.tbl1 ? state.account.shipmentDetails.tbl1 : [],
    table2: state.account.shipmentDetails.tbl2 ? state.account.shipmentDetails.tbl2 : [],
    table3: state.account.shipmentDetails.tbl3 ? state.account.shipmentDetails.tbl3 : [],
    accountLoading: state.account.accountLoading,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getShipmentDetails, setAccountLoadingTrue })(OrderDetails);
