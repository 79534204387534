import React from "react";
import { connect } from "react-redux";
import { uptoTwwoDecimal } from "../../reusable-functions/calculations";
import ButtonSecondary from "../../ui/button/ButtonSecondary";
import ListFreePrimary from "../../ui/list/ListFreePrimary";
import { postOrders, formStatusPending, formStatusNull, formStatusCustom } from "../../actions/forms";
import axios from "../../api";

const BillingInformation = ({ customClass, cart, postOrders, formStatusPending, formStatusNull, formStatusCustom }) => {
  async function order() {
    formStatusPending();
    const total = uptoTwwoDecimal(
      cart.reduce(function(accumulator, item) {
        let price = +item.amount * +item.qty;
        return accumulator + price;
      }, 0)
    );

    try {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, { action: "submit" });

        const res = await axios.get("/api/Home/ValidateCaptcha", {
          params: {
            captcha: token,
          },
        });

        if (res.data) {
          await postOrders(total);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        } else {
          formStatusCustom(404);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        }
      });
    } catch (error) {
      formStatusCustom(404);
      setTimeout(() => {
        formStatusNull();
      }, 3000);
    }
  }
  return (
    <ListFreePrimary customClass={customClass}>
      <h1 className="lead">Billing Information</h1>
      <hr />
      <div className="d-flex justify-content-between mb-1">
        <p className="font-weight-bold">Product</p>
        <p className="font-weight-bold">Qty</p>
        <p className="font-weight-bold">Price</p>
      </div>

      {cart.map((item) => (
        <div className="d-flex mb-2" key={item.title}>
          <p className="flex-even product-points">{item.title}</p>
          <p className="flex-even text-center">{item.qty}</p>
          <p className="flex-even text-right">$ {uptoTwwoDecimal(+item.amount * +item.qty)}</p>
        </div>
      ))}

      <hr />
      <div className="d-flex justify-content-between mb-2">
        <p className="font-weight-bold">Total</p>
        <p className="font-weight-bold">
          ${" "}
          {uptoTwwoDecimal(
            cart.reduce(function(accumulator, item) {
              let price = +item.amount * +item.qty;
              return accumulator + price;
            }, 0)
          )}
        </p>
      </div>
      <ButtonSecondary onClick={order} text="Place  Order" />
      {/* <div style={{ textAlign: "center", fontWeight: "bold" }}>Contact KanexPro to place order.</div> */}
    </ListFreePrimary>
  );
};

export default connect(null, { postOrders, formStatusPending, formStatusNull, formStatusCustom })(BillingInformation);
