import * as types from "../constants/action-types";
import axios from "../api/index";
import { arrayOfObjectSort } from "../reusable-functions/sort";
import { toast } from "react-toastify";
import axios2 from "axios";

// Get current user profile
export const getBannerProducts = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/Home/GetHomeBanner");
    dispatch({ type: types.GET_BANNER_PRODUCTS, payload: res.data.refFeatureProducts });
  } catch (error) {
    dispatch({
      type: types.PRODUCT_ERROR,
      payload: error,
    });
  }
};

export const getFeaturedProducts = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/Home/GetFeatureProducts");

    dispatch({ type: types.GET_FEATURED_PRODUCTS, payload: res.data.refFeatureProducts });
  } catch (error) {
    dispatch({
      type: types.PRODUCT_ERROR,
      payload: error,
    });
  }
};

export const getSubCategories = (category) => async (dispatch) => {
  try {
    const res = await axios.post("/api/Category/GetSubCategories", {
      product: category,
    });

    dispatch({ type: types.GET_SUB_CATEGORIES, payload: res.data.refSubCategories });
  } catch (error) {
    dispatch({
      type: types.PRODUCT_ERROR,
      payload: error,
    });
  }
};

export const getProducts = (category, subCategory) => async (dispatch) => {
  try {
    const res = await axios.post("/api/Category/GetSubCategoriesProductPage", {
      // IsDev: true,
      category: subCategory,
      categorygroup: category,
    });

    dispatch({ type: types.GET_PRODUCTS, payload: res.data.refSubCategoryProducts });
  } catch (error) {
    dispatch({
      type: types.PRODUCT_ERROR,
      payload: error,
    });
  }
};

export const getProductDetails = (skuId) => async (dispatch) => {
  try {
    const res = await axios.post("/api/Category/GetItemDetail", {
      skuid: skuId,
    });

    dispatch({ type: types.PRODUCT_DETAILS, payload: res.data });
  } catch (error) {
    dispatch({
      type: types.PRODUCT_ERROR,
      payload: error,
    });
  }
};

export const emptyProductDetails = () => async (dispatch) => {
  dispatch({ type: types.PRODUCT_DETAILS, payload: [] });
};

export const getProductsMenu = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/Home/GetAllProducts");

    const sortedMenu = res.data.sort(arrayOfObjectSort("category"));
    for (const inv of sortedMenu) {
      inv.subcategory.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    }

    dispatch({ type: types.PRODUCTS_MENU, payload: sortedMenu });
  } catch (error) {
    dispatch({
      type: types.PRODUCT_ERROR,
      payload: error,
    });
  }
};

export const addToCart = (item) => async (dispatch, getState) => {
  function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].id === obj.id) {
        return true;
      }
    }

    return false;
  }

  if (containsObject(item, getState().cart.cart)) {
    return;
  } else {
    dispatch({ type: types.ADD_CART, payload: item });
    toast.success("Added to cart", {
      position: "top-right",
      hideProgressBar: false,
      pauseOnHover: false,
      closeOnClick: true,
      autoClose: 3000,
    });
  }
};

export const removeFromCart = (itemId) => async (dispatch) => {
  dispatch({ type: types.REMOVE_CART, payload: itemId });
  toast.success("Removed from cart", {
    position: "top-right",
    hideProgressBar: false,
    pauseOnHover: false,
    closeOnClick: true,
    autoClose: 3000,
  });
};

export const emptyCart = () => (dispatch) => {
  dispatch({ type: types.EMPTY_CART });
};

export const changeProductQty = (item) => async (dispatch, getState) => {
  dispatch({ type: types.CHANGE_PRODUCT_QTY, payload: item });
};

export const setLoadingTrue = () => (dispatch) => {
  dispatch({ type: types.RESET_LOADING });
};

export const getSolutions = (id) => async (dispatch) => {
  try {
    const res = await axios.post("/api/Home/GetAppsInfo", {
      appId: id,
    });

    dispatch({ type: types.SOLUTIONS, payload: res.data });
  } catch (error) {
    dispatch({
      type: types.PRODUCT_ERROR,
      payload: error,
    });
  }
};

export const getNewProducts = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/Home/GetNewProducts");
    dispatch({ type: types.NEW_PRODUCTS, payload: res.data.refNewProducts });
  } catch (error) {
    dispatch({
      type: types.PRODUCT_ERROR,
      payload: error,
    });
  }
};

export const getSearchProducts = (term, page) => async (dispatch) => {
  try {
    const res = await axios.post("/api/Home/SearchProducts", null, {
      params: { term: term, page: page },
    });

    dispatch({ type: types.SEARCH_PRODUCTS, payload: res.data });
  } catch (error) {
    dispatch({
      type: types.PRODUCT_ERROR,
      payload: error,
    });
  }
};

export const getPartners = () => async (dispatch) => {
  try {
    const res = await axios.post("/api/Home/GetPartners");
    dispatch({ type: types.GET_PARTNER, payload: res.data });
  } catch (error) {
    console.log("error");
    dispatch({
      type: types.PARTNER_ERROR,
      payload: error,
    });
  }
};

export const getNews = () => async (dispatch) => {
  try {
    const res = await axios.post("/api/Home/GetNews");
    dispatch({ type: types.GET_NEWS, payload: res.data });
  } catch (error) {
    console.log("error");
    dispatch({
      type: types.NEWS_ERROR,
      payload: error,
    });
  }
};

export const getNewsdetails = (Id) => async (dispatch) => {
  try {
    const res = await axios.post("/api/Home/GetNewsDetails", {
      NewsId: Id,
    });
    dispatch({ type: types.NEWS_DETAILS, payload: res.data });
  } catch (error) {
    console.log("error");
    dispatch({
      type: types.NEWS_ERROR,
      payload: error,
    });
  }
};

export const setNewsLoadingTrue = () => (dispatch) => {
  dispatch({ type: types.NEWS_LOADING_TRUE });
};

export const getBlogs = (page, pageSize) => async (dispatch) => {
  try {
    const res = await axios.get("/api/blog", {
      params: {
        page, pageSize
      }
    });
    dispatch({ type: types.GET_BLOGS, payload: res.data });
  } catch (error) {
    console.log("error");
    dispatch({
      type: types.BLOGS_ERROR,
      payload: error,
    });
  }
};

export const getBlogDetails = (Id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/blog/${Id}`);
    console.log("data", res.data);

    dispatch({ type: types.BLOGS_DETAILS, payload: res.data });
  } catch (error) {
    console.log("error");
    dispatch({
      type: types.BLOGS_ERROR,
      payload: error,
    });
  }
};

export const setBlogLoadingTrue = () => (dispatch) => {
  dispatch({ type: types.BLOGS_LOADING_TRUE });
};
