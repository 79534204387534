import React, { useState, useEffect, useCallback } from "react";
import Hero from "../FlexMatrix/Hero.jpg";
import imgGovernment from "../FlexMatrix/imgGovernment.png";
import imgRestaurant from "../FlexMatrix/imgRestaurant.png";
import enterprise from "../FlexMatrix/enterprise.png";
import EmbeddedDocumentExample from '../FlexMatrix/EmbeddedDocumentExample.png';
import asburyalehouse from "../FlexMatrix/img-asbury-ale-house.png";
import products from '../FlexMatrix/Products.jpg';
import tataLogo from '../FlexMatrix/logo-taa.png';
import imgStep1 from '../FlexMatrix/img-step 1.png';
import imgStep2 from '../FlexMatrix/img-step 2.png';
import imgStep3 from '../FlexMatrix/img-step 3.png';
import imgStep4 from '../FlexMatrix/img-step 4.png';
import iconSpeed from '../FlexMatrix/icon-speed.svg';
import iconScaling from '../FlexMatrix/icon-scaling.svg';
import iconAudio from '../FlexMatrix/icon-audio.svg';
import iconCec from '../FlexMatrix/icon-cec.svg';
import iconMultiVew from '../FlexMatrix/icon-multi-vew.svg';
import iconVideoWall from '../FlexMatrix/icon-video-wall.svg';
import scrollbtn from '../FlexMatrix/button-scroll-down.svg';
import imgFlex from '../../images/imgAoc.png';
import howItWorks from "../FlexMatrix/howItWorks.png";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from '../../../src/api/index.js';
import AocCrousel from "./aoccrousel.js";
import { Helmet } from "react-helmet";
import Form from "../FlexMatrix/Form.jsx";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible &&
        <div onClick={scrollToTop}>
          {/* <FaArrowUp /> */}
        </div>
      }
    </div>
  );
};

export default function AocPage() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const onButtonComponentClick = useCallback(() => {
    const anchor = document.getElementById("howItWorks");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onClickArrow = useCallback(() => {
    const anchor = document.getElementById("flexMatrix");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onLogoBarExpoClick = useCallback(() => {
    window.open("https://www.barandrestaurantexpo.com/");
  }, []);

  const onButtonDemoClick = useCallback(() => {
    const anchor = document.getElementById("demo");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onButtonLearnmoreContainerClick = useCallback(() => {
    window.open("https://kanexpro.com/casestudy");
  }, []);

  const onButtonLearnmoreContainer2Click = useCallback(() => {
    window.open("https://kanexpro.com/support");
  }, []);

  const onButtonLearnmoreContainer3Click = useCallback(() => {
    window.open("https://kanexpro.com/casestudy");
  }, []);

  const initData = { firstName_chr: '', lastName_chr: '', email_chr: '', phone_chr: '', msg_chr: '' };
  const [formData, setFormData] = useState(initData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('form data', name, value);
    if (name === 'phone_chr') {
      const onlyNums = value.replace(/[^0-9*#]/g, '');
      setFormData((state) => ({ ...state, [name]: onlyNums }));
    } else {
      setFormData((state) => ({ ...state, [name]: value }));
    }
  };

  console.log('form data', formData);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted');
    try {
      const res = await axios.post("/api/EmailManagement/TechSupport", {
        name: `${formData.firstName_chr} ${formData.lastName_chr}`,
        email: formData.email_chr,
        phone: formData.phone_chr,
        comments: formData.msg_chr
      });
      console.log(res);
      setFormData(initData);
    } catch (error) {
      console.log('check err', error);
    }
  };

  return (
    <div className="">
      <Helmet>
        <title>Active Optic Cables</title>
        <meta name="description" content="Active Optic Cables"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className='image-container relative bg-gray-200 grid grid-cols-1 md:grid-cols-2 gap-y-32 p-8'>
        <div style={{ display: 'flex' }}>
          <div className="flex flex-col justify-center">
            <h1 style={{ color: "white", marginBottom: '20px' }}>Active Optic Cables</h1>
            <div className="HDiv2">Ensure Zero Data
              or Signal Loss</div>
            <div className="HDiv3">
              Experience 8K brilliance effortlessly with KanexPro's Active Optical Cables. Plug and Play simplicity, delivering clear images up to 8K@60Hz.
            </div>
            <div className="flex items-center gap-x-16 mt-8">
              <button
                className="w-96 p-4 border rounded-full uppercase"
                style={{ height: '70px' }}
                onClick={onButtonComponentClick}
              >
                See how it works
              </button>
              <button onClick={onClickArrow}>
                <img src={scrollbtn} />
              </button>
            </div>
          </div>
        </div>

        {/* Form */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'bottom' }}>
          <Form />
        </div>

      </div>
      <div className="bg-white grid grid-cols-1 gap-8 p-16 sm:grid-cols-2">
        <div className="md:pl-32 flex flex-col gap-y-8">
          <h1 className="w-full sm:w-2/3">Why Choose KanexPro’s Active Optic Cables</h1>
          <div className="h-16 border-b-2" style={{ width: '9vw', borderColor: '#FF671E' }}></div>
          <p className="w-full sm:w-2/3">
            Discover the prestige of multimedia connectivity offered by KanexPro's 8K Active Optical Cables (AOCs). Embrace superb convenience and performance as our Plug and Play solution seamlessly integrates into your setup, ending the need for firmware updates or external power supply. With support for the HDMI 2.1 standard and 48G Bandwidth, enjoy clear digital images in resolutions up to 8K@60Hz. Benefit from hybrid fiber and copper construction for reliable signal transmission without loss. Our AOCs are also backward compatible with HDMI 2.0/1.4, ensuring versatility across all your devices. Trust in HDCP 1.4/2.2 support and a self-detecting function for EDID information, guaranteeing hassle-free operation and compatibility. Experience the future of multimedia connectivity with KanexPro AOCs, where extraordinary convenience meets uncompromising performance.
          </p>
        </div>
        <div className="flex flex-col gap-y-16 pt-72 md:pl-32 sm:border-l-2">

          <div>
            <div className="head3">Seamless</div>
            <div className="w-full sm:w-2/3">
              Seamlessly integrate our AOCs into your setup with plug-and-play convenience and no need for external power, simplifying installation and ensuring hassle-free operation.
            </div>
          </div>
          <div>
            <div className="head3">Reliability</div>
            <div className="w-full sm:w-2/3">
              Trust in our hybrid fiber and copper construction, enjoy reliable signal transmission without loss.
            </div>
          </div>
          <div>
            <div className="head3">Performance</div>
            <p className="w-full sm:w-2/3">
              Experience 48G Bandwidth, delivering clear digital images in resolutions up to 8K@60Hz.
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-2 py-16 flex items-center justify-center">
        <button className="p-4 rounded-full text-white uppercase" style={{ background: '#FF671E', letterSpacing: '5px' }} onClick={onButtonDemoClick}>
          Connect with sales
        </button>
      </div>
      <div className="bg-white">
        <div className="grid grid-cols-1 gap-2 w-full items-center justify-center">

          <AocCrousel />

        </div>
      </div>
      <div className="grid grid-cols-3 gap-8 py-32 px-32" style={{ background: '#003E52' }}>
        <div className="col-span-3">
          <h2 className="keyFeatHead">Key features</h2>
          <div className="absolute top-210 left-142.2 w-32 h-1 bg-white"></div>
          <div className="pt-32 w-full sm:w-1/3">
            Discover 8K brilliance with KanexPro's Active Optical Cables (AOCs). Plug and play, supporting HDMI 2.1, with 48G Bandwidth for clear 8K@60Hz images. Backward compatible and hassle-free, they redefine multimedia connectivity.
          </div>
        </div>
        <div className="col-span-3"></div>
        <div className="col-span-3 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3" style={{ background: '#003E52' }}>
          <div className="keyFeatWrap">
            <div className="featName">Plug and Play</div>
            <p className="w-full sm:w-2/3" style={{ color: '#C9E3EC' }}>No firmware, no need external power supply</p>
          </div>
          <div className="keyFeatWrap">
            <div className="featName">HDMI Compatibility</div>
            <p className="w-full sm:w-2/3" style={{ color: '#C9E3EC' }}>Compatible with HDMI 2.1 standard</p>
          </div>
          <div className="keyFeatWrap">
            <div className="featName">Bandwidth Support</div>
            <p className="w-full sm:w-2/3" style={{ color: '#C9E3EC' }}>Support 48G Bandwidth with 4K/50p/60p/100p/120p and 8K/50p/60p</p>
          </div>
          <div className="keyFeatWrap">
            <div className="featName">No Loss of Signal</div>
            <p className="w-full sm:w-2/3" style={{ color: '#C9E3EC' }}>Delivering clear digital images instantly</p>
          </div>
          <div className="keyFeatWrap">
            <div className="featName">8K and HDCP</div>
            <p className="w-full sm:w-2/3" style={{ color: '#C9E3EC' }}>Support HDCP 1.4 / 2.2, Support up to 8K and 8K@60Hz (DisplayPort 2.0)</p>
          </div>
          <div className="keyFeatWrap">
            <div className="featName">Backward Compatible</div>
            <p className="w-full sm:w-2/3" style={{ color: '#C9E3EC' }}>Backward compatible with HDMI2.0/HDMI1.4</p>
          </div>
        </div>
      </div>

      <ScrollToTop />
    </div>
  );
}
