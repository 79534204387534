import {
  ORDER_HISTORY_CLOSE,
  ORDER_HISTORY_OPEN,
  PRODUCT_LIST,
  SKUHISTORY_LIST,
  SHIPMENTS_LIST,
  SHIPMENT_DETAILS,
  ACCOUNT_TRUE,
  OPEN_ITEMS,
  OPEN_ITEMS_COMMENT,
} from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  productList: [],
  skuHistoryList: [],
  orderHistoryOpen: [],
  orderHistoryClose: [],
  shipmentsList: [],
  shipmentDetails: {},
  openItems: null,
  openItemsComment: null,
  accountLoading: true,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload,
      };
    case SKUHISTORY_LIST:
      return {
        ...state,
        skuHistoryList: action.payload,
      };
    case ORDER_HISTORY_OPEN:
      return {
        ...state,
        orderHistoryOpen: action.payload,
      };
    case ORDER_HISTORY_CLOSE:
      return {
        ...state,
        orderHistoryClose: action.payload,
      };
    case SHIPMENTS_LIST:
      return {
        ...state,
        shipmentsList: action.payload,
      };
    case SHIPMENT_DETAILS:
      return {
        ...state,
        shipmentDetails: action.payload,
        accountLoading: false,
      };
    case OPEN_ITEMS:
      return {
        ...state,
        openItems: action.payload,
      };
    case OPEN_ITEMS_COMMENT:
      return {
        ...state,
        openItemsComment: action.payload,
      };
    case ACCOUNT_TRUE:
      return {
        ...state,
        accountLoading: true,
      };

    default:
      return state;
  }
};
const persistConfig = {
  keyPrefix: "molla-",
  key: "account",
  storage,
};

export default persistReducer(persistConfig, accountReducer);
