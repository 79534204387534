import React from "react";
import { bytesToSize } from "../../../reusable-functions/calculations";

const Downloads = ({ data }) => {
  return (
    <div className="container fade-in">
      <div className="list-primary downloads">
        <h1 className="display-5 fs-m">Downloads</h1>

        <div className="product-points">
          {data.map((file, index) => (
            <div key={index}>
              <a href={"https://www.kanexpro.com/uploads/" + file.filelink} download target="_blank" rel="noreferrer">
                {file.filetype}
              </a>
              <p>{bytesToSize(file.filesize)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Downloads;
