import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import "./product.scss";
import ProductDisplay from "./ProductDisplay";
import ProductDetails from "./product-details";
import Sidebar from "../products/Sidebar";
import { connect } from "react-redux";
import { getProductDetails, setLoadingTrue, emptyProductDetails } from "../../actions/products";
import { products } from "../common/partials/navData";
import { itemData } from "./itemData";
import LoaderPrimary from "../../ui/loader/LoaderPrimary";


const canonicalUrls = {
  "AUD-DTE-DCODE": "https://kanexpro.com/item/AUD-DTE-DCODE",
  "AUD-DTE-ECODE": "https://kanexpro.com/item/AUD-DTE-ECODE",
  "AUD-DTE-USB": "https://kanexpro.com/item/AUD-DTE-USB",
  "AUD-EARC-8K": "https://kanexpro.com/item/AUD-EARC-8K",
  "AUD2ACV": "https://kanexpro.com/item/AUD2ACV",
  "CBL-AOC100M4K": "https://kanexpro.com/item/CBL-AOC100M4K",
  "CBL-AOC30M4K": "https://kanexpro.com/item/CBL-AOC30M4K",
  "CBL-AOC50M4K": "https://kanexpro.com/item/CBL-AOC50M4K",
  "CBL-AOCUSBC-15M": "https://kanexpro.com/item/CBL-AOCUSBC-15M",
  "CBL-DP14AOC30M": "https://kanexpro.com/item/CBL-DP14AOC30M",
  "CBL-DP14AOC50M": "https://kanexpro.com/item/CBL-DP14AOC50M",
  "CBL-HDMIAOC20M": "https://kanexpro.com/item/CBL-HDMIAOC20M",
  "CBL-HDMIAOC30M": "https://kanexpro.com/item/CBL-HDMIAOC30M",
  "CBL-HDMIAOC50M": "https://kanexpro.com/item/CBL-HDMIAOC50M",
  "CBL-HDMICERTSS3FT": "https://kanexpro.com/item/CBL-HDMICERTSS3FT",
  "CBL-HDMICERTSS6FT": "https://kanexpro.com/item/CBL-HDMICERTSS6FT",
  "CBL-HT7180HDMI75FT": "https://kanexpro.com/item/CBL-HT7180HDMI75FT",
  "CBL-HT8181HDMI50FT": "https://kanexpro.com/item/CBL-HT8181HDMI50FT",
  "CON-AV-HD4K": "https://kanexpro.com/item/CON-AV-HD4K",
  "CON-GAMECAP": "https://kanexpro.com/item/CON-GAMECAP",
  "CON-GAMECAPL": "https://kanexpro.com/item/CON-GAMECAPL",
  "CON-VGA-HD4K": "https://kanexpro.com/item/CON-VGA-HD4K",
  "CR-3XCONTROL": "https://kanexpro.com/item/CR-3XCONTROL",
  "EXT-18GHDMIWL": "https://kanexpro.com/item/EXT-18GHDMIWL",
  "EXT-70SL18G": "https://kanexpro.com/item/EXT-70SL18G",
  "EXT-AVIP120M": "https://kanexpro.com/item/EXT-AVIP120M",
  "EXT-AVIP120MRX": "https://kanexpro.com/item/EXT-AVIP120MRX",
  "EXT-AVIPH264RX": "https://kanexpro.com/item/EXT-AVIPH264RX",
  "EXT-AVIPH264TX": "https://kanexpro.com/item/EXT-AVIPH264TX",
  "EXT-HD100MHBT": "https://kanexpro.com/item/EXT-HD100MHBT",
  "EXT-HD50C": "https://kanexpro.com/item/EXT-HD50C",
  "EXT-HD60M": "https://kanexpro.com/item/EXT-HD60M",
  "EXT-HDBT150M": "https://kanexpro.com/item/EXT-HDBT150M",
  "EXT-HDBT3ARC-TX": "https://kanexpro.com/item/EXT-HDBT3ARC-TX",
  "EXT-HDBT70M": "https://kanexpro.com/item/EXT-HDBT70M",
  "EXT-HDBT70MTX": "https://kanexpro.com/item/EXT-HDBT70MTX",
  "EXT-HDBTKVM100": "https://kanexpro.com/item/EXT-HDBTKVM100",
  "EXT-HDMICAS70M": "https://kanexpro.com/item/EXT-HDMICAS70M",
  "EXT-HDMICAS70MRX": "https://kanexpro.com/item/EXT-HDMICAS70MRX",
  "EXT-HDMIKVM70M": "https://kanexpro.com/item/EXT-HDMIKVM70M",
  "EXT-PROCTRL": "https://kanexpro.com/item/EXT-PROCTRL",
  "EXT-PROMATRIXUSB": "https://kanexpro.com/item/EXT-PROMATRIXUSB",
  "EXT-PRORACK": "https://kanexpro.com/item/EXT-PRORACK",
  "EXT-USB16FT": "https://kanexpro.com/item/EXT-USB16FT",
  "EXT-USB250M": "https://kanexpro.com/item/EXT-USB250M",
  "FLEX-CAT12OUT": "https://kanexpro.com/item/FLEX-CAT12OUT",
  "FLEX-CATRX": "https://kanexpro.com/item/FLEX-CATRX",
  "FLEX-HDMI12OUT": "https://kanexpro.com/item/FLEX-HDMI12OUT",
  "FLEX-HDMI8IN": "https://kanexpro.com/item/FLEX-HDMI8IN",
  "FLEX-IN-DVI": "https://kanexpro.com/item/FLEX-IN-DVI",
  "FLEX-IN-HD": "https://kanexpro.com/item/FLEX-IN-HD",
  "FLEX-IN-HD4K": "https://kanexpro.com/item/FLEX-IN-HD4K",
  "FLEX-IN-HDBT4K": "https://kanexpro.com/item/FLEX-IN-HDBT4K",
  "FLEX-IN-SDI": "https://kanexpro.com/item/FLEX-IN-SDI",
  "FLEX-IN-VGA": "https://kanexpro.com/item/FLEX-IN-VGA",
  "FLEX-MF24X36": "https://kanexpro.com/item/FLEX-MF24X36",
  "FLEX-MMX16": "https://kanexpro.com/item/FLEX-MMX16",
  "FLEX-MMX32": "https://kanexpro.com/item/FLEX-MMX32",
  "FLEX-OUT-DVI": "https://kanexpro.com/item/FLEX-OUT-DVI",
  "FLEX-OUT-HD4K": "https://kanexpro.com/item/FLEX-OUT-HD4K",
  "FLEX-OUT-HDBT4K": "https://kanexpro.com/item/FLEX-OUT-HDBT4K",
  "FLEX-OUT-VGA": "https://kanexpro.com/item/FLEX-OUT-VGA",
  "HD25FTCL314": "https://kanexpro.com/item/HD25FTCL314",
  "HD4PSPM": "https://kanexpro.com/item/HD4PSPM",
  "HDBASE70POER": "https://kanexpro.com/item/HDBASE70POER",
  "HDMMX3232-4K": "https://kanexpro.com/item/HDMMX3232-4K",
  "HDMMX6464-4K": "https://kanexpro.com/item/HDMMX6464-4K",
  "HDMX42-18G": "https://kanexpro.com/item/HDMX42-18G",
  "HDMX42A-18G": "https://kanexpro.com/item/HDMX42A-18G",
  "HDMX44-18G": "https://kanexpro.com/item/HDMX44-18G",
  "HDMX44-4K": "https://kanexpro.com/item/HDMX44-4K",
  "HDMX88-18G": "https://kanexpro.com/item/HDMX88-18G",
  "HDMX88A-18G": "https://kanexpro.com/item/HDMX88A-18G",
  "HDRPT4K": "https://kanexpro.com/item/HDRPT4K",
  "HDSC31D-4K": "https://kanexpro.com/item/HDSC31D-4K",
  "HDSC71D-4K": "https://kanexpro.com/item/HDSC71D-4K",
  "HDSC92D-4K": "https://kanexpro.com/item/HDSC92D-4K",
  "HDSC92D-4KTX": "https://kanexpro.com/item/HDSC92D-4KTX",
  "HDVGARL": "https://kanexpro.com/item/HDVGARL",
  "MMX-4X4CAT-70M": "https://kanexpro.com/item/MMX-4X4CAT-70M",
  "MOD-IN-CAT6-4K": "https://kanexpro.com/item/MOD-IN-CAT6-4K",
  "MOD-IN-FIBER": "https://kanexpro.com/item/MOD-IN-FIBER",
  "MOD-IN-HDTV-4K": "https://kanexpro.com/item/MOD-IN-HDTV-4K",
  "MOD-OUT-CAT6-4K": "https://kanexpro.com/item/MOD-OUT-CAT6-4K",
  "MOD-OUT-FIBER": "https://kanexpro.com/item/MOD-OUT-FIBER",
  "MOD-OUT-HDTV-4K": "https://kanexpro.com/item/MOD-OUT-HDTV-4K",
  "MOT-ART-1343": "https://kanexpro.com/item/MOT-ART-1343",
  "MOT-ART-3780": "https://kanexpro.com/item/MOT-ART-3780",
  "MOT-FIX-3270": "https://kanexpro.com/item/MOT-FIX-3270",
  "MOT-FIX-4390": "https://kanexpro.com/item/MOT-FIX-4390",
  "MOT-TIL-3780": "https://kanexpro.com/item/MOT-TIL-3780",
  "MOT-TIL-4390": "https://kanexpro.com/item/MOT-TIL-4390",
  "MX-HDBT8X818G": "https://kanexpro.com/item/MX-HDBT8X818G",
  "RGBRLHD": "https://kanexpro.com/item/RGBRLHD",
  "SDI-HDRPTPRO": "https://kanexpro.com/item/SDI-HDRPTPRO",
  "SDI-HDSDXPRO": "https://kanexpro.com/item/SDI-HDSDXPRO",
  "SDI-MULTI2SDIPRO": "https://kanexpro.com/item/SDI-MULTI2SDIPRO",
  "SDI-SDHDXPRO": "https://kanexpro.com/item/SDI-SDHDXPRO",
  "SP-1X2A8K": "https://kanexpro.com/item/SP-1X2A8K",
  "SP-1X2SL18G": "https://kanexpro.com/item/SP-1X2SL18G",
  "SP-1X4A8K": "https://kanexpro.com/item/SP-1X4A8K",
  "SP-1X4SL18G": "https://kanexpro.com/item/SP-1X4SL18G",
  "SP-1X8HD150M": "https://kanexpro.com/item/SP-1X8HD150M",
  "SP-HD1X24K": "https://kanexpro.com/item/SP-HD1X24K",
  "SP-HD1X28K": "https://kanexpro.com/item/SP-HD1X28K",
  "SP-HD1X48K": "https://kanexpro.com/item/SP-HD1X48K",
  "SP-HDCAT1X4": "https://kanexpro.com/item/SP-HDCAT1X4",
  "SP-HDPOC1X4": "https://kanexpro.com/item/SP-HDPOC1X4",
  "SW-2X1A8K": "https://kanexpro.com/item/SW-2X1A8K",
  "SW-2X28K": "https://kanexpro.com/item/SW-2X28K",
  "SW-4X18K": "https://kanexpro.com/item/SW-4X18K",
  "SW-4X1HDAUD": "https://kanexpro.com/item/SW-4X1HDAUD",
  "SW-4X1KVMMV": "https://kanexpro.com/item/SW-4X1KVMMV",
  "SW-HD20-3X14K": "https://kanexpro.com/item/SW-HD20-3X14K",
  "SW-HD3X14K": "https://kanexpro.com/item/SW-HD3X14K",
  "SW-HD4X1AUD4K": "https://kanexpro.com/item/SW-HD4X1AUD4K",
  "SW-HD5X14K": "https://kanexpro.com/item/SW-HD5X14K",
  "SW-HDMX44CE": "https://kanexpro.com/item/SW-HDMX44CE",
  "SW-HDSC4X1KVM": "https://kanexpro.com/item/SW-HDSC4X1KVM",
  "SW-HDSC51HDBT": "https://kanexpro.com/item/SW-HDSC51HDBT",
  "SW-HDSC914K": "https://kanexpro.com/item/SW-HDSC914K",
  "USBEXTRJ45": "https://kanexpro.com/item/USBEXTRJ45",
  "VGAEXTX1": "https://kanexpro.com/item/VGAEXTX1",
  "VGAEXTX2": "https://kanexpro.com/item/VGAEXTX2",
  "VGAEXTX4": "https://kanexpro.com/item/VGAEXTX4",
  "VGARLHD": "https://kanexpro.com/item/VGARLHD",
  "VW-SP1X44K": "https://kanexpro.com/item/VW-SP1X44K",
  "WP-2X1HDUSBC": "https://kanexpro.com/item/WP-2X1HDUSBC",
  "WP-CONTROLB": "https://kanexpro.com/item/WP-CONTROLB",
  "WP-CONTROLS": "https://kanexpro.com/item/WP-CONTROLS",
  "WP-EDUKIT4K": "https://kanexpro.com/item/WP-EDUKIT4K",
  "WP-EXTHDBTKIT": "https://kanexpro.com/item/WP-EXTHDBTKIT",
  "WP-EXTHDBTX1": "https://kanexpro.com/item/WP-EXTHDBTX1",
  "WP-EXTHDMDP50": "https://kanexpro.com/item/WP-EXTHDMDP50",
  "WP-EXTHDTC50": "https://kanexpro.com/item/WP-EXTHDTC50",
  "WP-HDBASETX": "https://kanexpro.com/item/WP-HDBASETX",
  "WP-HDBTKVM3": "https://kanexpro.com/item/WP-HDBTKVM3",
  "WP-IPKEYPAD8": "https://kanexpro.com/item/WP-IPKEYPAD8"
};


const Product = (props) => {
  const {
    productsMenu,
    productDisplay,
    productDiagram,
    productPanel,
    productDownload,
    getProductDetails,
    productStocks,
    setLoadingTrue,
    loading,
    location,
    emptyProductDetails,
  } = props;

  const [activeBar, setActiveBar] = useState("overview");
  const inquireRef = useRef(null);

  const skuId = location.pathname.replace("/item/", "");
  const skuIdWithoutHyphen = skuId.includes("-") ? skuId.replace(/-/g, "") : skuId;

  useEffect(() => {
    getProductDetails(skuId);

    return () => {
      emptyProductDetails();
      setLoadingTrue();
    };
  }, []);

  const path =
    productDisplay[0] &&
    (productDisplay[0].kproparent.toLowerCase() === "cabling" ? "cables" : productDisplay[0].kproparent.toLowerCase());

  const kproChildPath =
    productDisplay[0] &&
    (productDisplay[0].kprochild.includes(" ")
      ? productDisplay[0].kprochild.replace(" ", "-").toLowerCase()
      : productDisplay[0].kprochild.toLowerCase());

  const subPath = productDisplay[0] && `${path}/${kproChildPath}`;

  const canonicalUrl = canonicalUrls[skuId] || "https://kanexpro.com";

  if (productDisplay.length === 0 || loading) {
    return <LoaderPrimary />;
  }

  return (
    <>
      <Helmet>
        <title>{itemData[skuIdWithoutHyphen] ? itemData[skuIdWithoutHyphen].title : ""}</title>
        <meta
          name="description"
          content={
            itemData[skuIdWithoutHyphen]
              ? itemData[skuIdWithoutHyphen].description
              : "Professional Audio Video components"
          }
        ></meta>
        <meta
          name="keywords"
          content={itemData[skuIdWithoutHyphen] ? itemData[skuIdWithoutHyphen].keywords : "kanexpro"}
        ></meta>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="product-container">
        <div className="items">
          <div className="d-none d-lg-block d-xl-block">
            {/* <Sidebar data={products} /> */}
            <Sidebar path={`/${path}`} subPath={`/${subPath}`} data={products} />
          </div>
          <div className="product-display">
            <ProductDisplay
              data={productDisplay[0]}
              stock={productStocks[0]}
              activeBar={activeBar}
              setActiveBar={setActiveBar}
              inquireRef={inquireRef}
            />
            <ProductDetails
              data={productDisplay[0]}
              productDiagram={productDiagram}
              productPanel={productPanel}
              productDownload={productDownload}
              location={location}
              activeBar={activeBar}
              setActiveBar={setActiveBar}
              inquireRef={inquireRef}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    productDisplay: state.data.productDetails.refItemDetail ? state.data.productDetails.refItemDetail : [],
    productDiagram: state.data.productDetails.refDiagram ? state.data.productDetails.refDiagram : [],
    productPanel: state.data.productDetails.refPanel ? state.data.productDetails.refPanel : [],
    productDownload: state.data.productDetails.refDownloads ? state.data.productDetails.refDownloads : [],
    // productDiagram: state.data.refDiagram ? state.data.refDiagram : [],
    productStocks: state.data.productDetails.refStocks ? state.data.productDetails.refStocks : [],
    productsMenu: state.data.productsMenu ? state.data.productsMenu : [],
    loading: state.data.loading,
  };
};

export default connect(mapStateToProps, { getProductDetails, setLoadingTrue, emptyProductDetails })(Product);
