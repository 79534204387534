import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import InputSearchSecondary from "../../../ui/input/InputSearchSecondary";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import { account } from "../../common/partials/navData";
import ButtonPrimary from "../../../ui/button/ButtonPrimary";
import { getProductList } from "../../../actions/account";
import "./product-list.scss";
import { exportCsv } from "./exportCsv";
import LoaderPrimary from "../../../ui/loader/LoaderPrimary";
import { uptoTwwoDecimal } from "../../../reusable-functions/calculations";

const ProductList = (props) => {
  const { location } = props;
  const { getProductList, productList, history, auth } = props;

  if (!auth.isAuthenticated) {
    history.push("/");
  }

  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);

  useEffect(() => {
    getProductList("skulist", "C100578");
  }, []);

  const scrollRef = useRef();
  const scrollTable = (direction, tableScrollRef) => {
    if (direction === "left") {
      tableScrollRef.current.scrollLeft -= 100;
    }
    if (direction === "right") {
      tableScrollRef.current.scrollLeft += 100;
    }
  };

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (search === "") {
      setList(productList);
    } else {
      const filteredList = productList.filter(
        (item) =>
          item.gname.toLowerCase().includes(search.toLowerCase()) ||
          item.Sku.toLowerCase().includes(search.toLowerCase())
      );
      setList(filteredList);
    }
  }, [search]);

  useEffect(() => {
    setList(productList);
  }, [productList]);

  function myPrice(stock) {
    // dealer: 157.49
    // dealer2: 165.6
    // direct: 146.99
    // direct2: 132.48
    // distiADI: 146.99
    // intlold: 0
    // msrp: 262.49
    // msrp1: 262.49
    // msrp2: 276
    // msrpint: 276
    // stock: 7
    if (auth.userInfo.AccountType === "Dealer") {
      return uptoTwwoDecimal(stock.dealer);
    } else if (auth.userInfo.AccountType === "Distributor") {
      return uptoTwwoDecimal(stock.direct);
    } else if (auth.userInfo.AccountType === "DealerInt") {
      return uptoTwwoDecimal(stock.dealer2);
    } else if (auth.userInfo.AccountType === "DistributorInt") {
      return uptoTwwoDecimal(stock.direct2);
    } else {
      return uptoTwwoDecimal(stock.msrp);
    }
  }

  const renderList = () => {
    return list.map((item, index) => (
      <tr key={index}>
        <td className="s-no">{index + 1}</td>
        <td>{item.gname}</td>
        <td>
          <Link to={`/item/${item.Sku}`}>{item.Sku}</Link>
        </td>
        <td>{item.msrp}</td>
        <td>{myPrice(item)}</td>
        <td>
          {item.stockUS} / {item.ASNUS}
        </td>
        <td>{item.ship}</td>
        <td>{item.status}</td>
      </tr>
    ));
  };

  const exportToExcel = () => {
    exportCsv(productList);
  };

  if (productList.length === 0) {
    return <LoaderPrimary />;
  }

  return (
    <>
      <Helmet>
        <title>Product List</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={account} title="Account" />
        </div>
        <div className="product-list">
          <h1 className="display-4 mb-3">Product List</h1>
          <ListFreePrimary>
            <InputSearchSecondary customClass="mb-2" onChange={onChange} value={search} />
            <ButtonPrimary text="Download Data" customClass="mb-2" onClick={exportToExcel} />
            <span className="d-block">
              <span className="scroll-btn" onClick={() => scrollTable("left", scrollRef)}>
                ❮
              </span>{" "}
              Scroll{" "}
              <span className="scroll-btn" onClick={() => scrollTable("right", scrollRef)}>
                ❯
              </span>
            </span>

            <div ref={scrollRef} className="scroll-x">
              <table className="table">
                <thead className="row-brown">
                  <tr>
                    <th scope="col">S.no</th>
                    <th scope="col">Category</th>
                    <th scope="col">Sku</th>
                    <th scope="col">MSRP</th>
                    <th scope="col">My Price</th>
                    <th scope="col">US / Inbound</th>
                    <th scope="col">Shipped</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>

                <tbody>{renderList()}</tbody>
              </table>
            </div>
          </ListFreePrimary>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    productList: state.account.productList ? state.account.productList : [],
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getProductList })(ProductList);
