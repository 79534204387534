import React, { useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";

export default function Terms() {
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <div className="info">
      <div className="section">
        <p className="font-weight-bold">
          PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING THIS WEBSITE.
        </p>
        <p>
          All users of this site agree that access to and use of this site are subject to the following terms and
          conditions and other applicable law. If you do not agree to these terms and conditions, please do not use this
          site.
        </p>
      </div>

      <div className="section">
        <h1 className="display-5">Information Available on this Web Site</h1>
        <p>
          The contents of this Web site including, but not limited to all images, graphics, logos, sounds, and written
          content are the property of KanexPro and may not be re-produced, modified, copied, or re-displayed in any way
          without the expressed, written consent of KanexPro.
        </p>
        <p>
          KanexPro makes every effort to ensure the accuracy of information on this site and to keep all contents
          current. The documents, graphics, and other information included on this site may include technical
          inaccuracies and typographical, or other, errors. KanexPro reserves the right to change or remove information
          at any time without notice.
        </p>
      </div>

      <div className="section">
        <h1 className="display-5">Use of KanexPro Logos and Product Photos</h1>
        <p>
          The KanexPro logo and product photos are the sole and exclusive property of KanexPro, and may be used only by
          Media and KanexPro authorized dealers and consultants on their printed materials and on their Web sites. The
          logo may not be used on any product nor may it be used in any manner that, in the sole discretion of KanexPro,
          discredits KanexPro in any false, misleading, or unlawful manner.
        </p>
        <p>
          The KanexPro logo and product photos are available in camera-ready or electronic form. The logo may not be
          revised or altered in any way and must be displayed as produced by KanexPro. Logo slicks of camera ready art
          of the KanexPro logo or KanexPro products for use on printed materials may be obtained by contacting your
          KanexPro Sales Representative.
        </p>
      </div>

      <div className="section">
        <h1 className="display-5">Copyright</h1>
        <p>
          The entire content included in this site, including but not limited to text, graphics or code is copyrighted
          as a collective work under the United States and other copyright laws, and is the property of KanexPro.
          Permission is granted to electronically copy and print hard copy portions of this site for the sole purpose of
          placing an order with KanexPro or purchasing KanexPro -branded products. You may display and, subject to any
          expressly stated restrictions or limitations relating to specific material, download or print portions of the
          material from the different areas of the site solely for your own non-commercial use, or to place an order
          with KanexPro or to purchase KanexPro -branded products. Any other use, including but not limited to the
          reproduction, distribution, display or transmission of the content of this site is strictly prohibited, unless
          authorized by KanexPro. You further agree not to change or delete any proprietary notices from materials
          downloaded from the site.
        </p>
      </div>

      <div className="section">
        <h1 className="display-5">Hyperlinks</h1>
        <p>
          Any external link to this Web site must meet the following criteria: 1) The referring Web site must be used
          primarily for the purpose of conducting business related to the audio/video industry or for the purpose of
          providing Internet portal/search services; and 2) The referring Web site may not embed KanexPro Web site in a
          collective container or window. This includes framesets, client applications, and other, similar display
          mechanisms.
        </p>
        <p>
          Any link to an external site listed on this Web site is provided for convenience only. KanexPro does not
          endorse or otherwise make any claims of suitability, accuracy, or applicability of any information contained
          in any external site linked to this Web site. KanexPro is not responsible for the contents or information
          contained in any linked Web site.
        </p>
      </div>

      <div className="section">
        <h1 className="display-5">Trademarks</h1>
        <p>
          All trademarks, logo marks, and trade names used in this site are the registered or unregistered trademarks of
          KanexPro or third parties. Blu-ray Disc is a trademark owned by Blu-ray Disc Association. Dolby and the
          double-D symbol are registered trademarks of Dolby Laboratories. RCA is a trademark of RCA Trademark
          Management TOSLINK is a registered trademark of Toshiba Corporation. USB and the USB-IF Logos are trademarks
          of Universal Serial Bus Implementers Forum, Inc. DisplayPort is a trademark of VESA. Apple Cinema Display and
          Mac are trademarks of Apple Inc., registered in the U.S. and other countries. DVI is a trademark of the
          Digital Display Working Group. HDBaseT is a registered trademark of Valens Semiconductor Ltd.
        </p>
        <p>
          HDMI, the HDMI logo, and High-Definition Multimedia Interface are trademarks or registered trademarks of HDMI
          Licensing, LLC in the United States and/or other countries.
        </p>
        <p>All other product or service names are the property of their respective owners.</p>
      </div>

      <div className="section">
        <h1 className="display-5">Reviews, Comments & Communications</h1>
        <p>
          You may post reviews, comments, and other content, send communications, and submit suggestions, ideas,
          comments, questions, or other information, so long as the content is not illegal, obscene, threatening,
          defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third
          parties or objectionable and does not consist of or contain software viruses, political campaigning,
          commercial solicitation, chain letters, mass mailings
        </p>
        <p>
          If you do post content or submit material, and unless we indicate otherwise, you grant KanexPro a
          nonexclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify,
          adapt, publish, translate, create derivative works from, distribute, and display such content throughout the
          world in any media. You grant KanexPro the right to use the name that you submit in connection with such
          content. You represent and warrant that you own or otherwise control all of the rights to the content that you
          post and that the content is accurate. KanexPro has the right but not the obligation to monitor and edit or
          remove any activity or content.
        </p>
      </div>

      <div className="section">
        <h1 className="display-5">Typographical Errors</h1>
        <p>
          KanexPro reserves the right to correct any typographical errors which may be present in prices,
          specifications, quotations, or acknowledgements.
        </p>
      </div>

      <div className="section">
        <h1 className="display-5">Limitation of Liability</h1>
        <p>
          KanexPro is not responsible for any damages caused by the misuse of, or damage caused by any KanexPro-branded
          product. In no event shall KanexPro be liable for any damages in connection with or arising from the use of
          any product purchased.
        </p>
      </div>

      <div className="section">
        <h1 className="display-5">Shipping</h1>
        <p>
          KanexPro is not responsible for any lost or stolen packages. It is recommended that you obtain a tracking
          number for your shipments.
        </p>
      </div>

      <div className="section">
        <h1 className="display-5">Termination</h1>
        <p>
          These terms and conditions are applicable to you upon your accessing the site and/or completing the
          registration or shopping process. These terms and conditions, or any part of them, may be terminated by
          KanexPro without notice at any time, for any reason. The provisions relating to Copyrights, Trademark,
          Limitation of Liability, Indemnification and Miscellaneous, shall survive any termination.
        </p>
      </div>

      <div className="section">
        <h1 className="display-5">Indemnification</h1>
        <p>
          You agree to indemnify, defend, and hold harmless KanexPro, its officers, directors, employees, agents,
          licensors and suppliers (collectively the "Service Providers") from and against all losses, expenses, damages
          and costs, including reasonable attorneys' fees, resulting from any violation of these terms and conditions or
          any activity related to your account (including negligent or wrongful conduct) by you or any other person
          accessing the site using your Internet account.
        </p>
        <p>
          By placing and submitting an order through the Kanexpro.com website, you must agree to all terms and
          conditions set above.
        </p>
      </div>
    </div>
  );
}
