import React from "react";
import { about } from "../common/partials/navData";
import SidebarSecondary from "../common/sidebar/SidebarSecondary";
import Blog from "./Blog";
import "./BlogDesc/BlogDescription.css";
import "./Blog.css";

export default function Blogs({ location, match }) {

  return (
    <>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={about} title="About" />
        </div>

        <div className="blog-description w-100" >
          <Blog match={match} />
        </div>
      </div>
    </>
  );
}
