export const arrayOfObjectSort = (key) => {
  var sortOrder = 1;

  if (key[0] === "-") {
    sortOrder = -1;
    key = key.substr(1);
  }

  return function(a, b) {
    if (sortOrder == -1) {
      return b[key].localeCompare(a[key]);
    } else {
      return a[key].localeCompare(b[key]);
    }
  };
};
