import React, { useState } from "react";
import "./input-search.scss";

const InputSearch = ({ placeholder, customClass, value, onChange, onSearch }) => {
  const [background, setBackground] = useState(false);
  return (
    <div
      className={`input-search ${background ? "" : ""} ${customClass}`}
      onFocus={() => setBackground(true)}
      onBlur={() => setBackground(false)}
    >
      <i className="icon-search" onClick={(e) => onSearch(e, true)}></i>
      <input
        type="text"
        className="input-search__input"
        placeholder={placeholder ? placeholder : "Search..."}
        value={value}
        onChange={onChange}
        onKeyPress={(e) => onSearch(e, false)}
      />
    </div>
  );
};

export default InputSearch;
