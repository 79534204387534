import React from "react";
import CardPrimary from "../../ui/CardPrimary";
import BillingInformation from "./BillingInformation";
import { connect } from "react-redux";
import { removeFromCart, changeProductQty } from "../../actions/products";
import "./cart.scss";
import ShippingInformation from "./ShippingInformation";
import { noSpaceOnChange, numberValidation } from "../../validation/formValidation";

const Cart = (props) => {
  const { cart, removeFromCart, changeProductQty, userInfo } = props;

  const onRemoveItem = (itemId) => {
    removeFromCart(itemId);
  };

  const changeQty = (item, type, e) => {
    if (type === "dec" && item.qty > 1) {
      item.qty = item.qty - 1;
      changeProductQty(item);
    }
    if (type === "inc" && item.stock > item.qty) {
      item.qty = item.qty + 1;
      changeProductQty(item);
    }
    if (type === "chn" && item.stock >= e.target.value) {
      if (noSpaceOnChange(e.target.value) && numberValidation(e.target.value)) {
        item.qty = +e.target.value;
        changeProductQty(item);
      }
    }
    if (type === "blu") {
      if (item.qty <= 0) {
        item.qty = 1;
        changeProductQty(item);
      }
    }
  };

  return (
    <div className="cart-container">
      <h1 className="display-4">Cart</h1>
      {cart.length !== 0 ? (
        <div className="row">
          <div className="col-xl-7">
            {cart.map((item) => (
              <CardPrimary
                key={item.id}
                title={item.title}
                subtitle={item.subtitle}
                image={item.img}
                amount={item.amount}
                qty={item.qty}
                stock={item.stock}
                icon="fas fa-times"
                btnIcon="fas fa-times ml-3"
                link={`${process.env.PUBLIC_URL}/item/${item.id}`}
                onRemove={() => onRemoveItem(item.id)}
                onDecrease={() => changeQty(item, "dec")}
                onIncrease={() => changeQty(item, "inc")}
                onChange={(e) => changeQty(item, "chn", e)}
                onBlur={() => changeQty(item, "blu")}
              />
            ))}
          </div>

          <div className="col-xl-5">
            <ShippingInformation customClass="mb-4" userInfo={userInfo} />
            <BillingInformation cart={cart} userInfo={userInfo} />
          </div>
        </div>
      ) : (
        <div className="no-items">
          <p>Oops! No items in cart.</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    userInfo: state.auth.userInfo,
  };
};
export default connect(mapStateToProps, { removeFromCart, changeProductQty })(Cart);
