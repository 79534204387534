import React, { useState } from "react";
import Diagram from "./Diagram";
import Downloads from "./Downloads";
import Faq from "../../forms/Faq";
import Overview from "./Overview";
import Panel from "./Panel";
import Specs from "./Specs";
import "./product-details.scss";
import InquireProduct from "../../forms/InquireProduct";

const ProductDetails = (props) => {
  const { data, productDiagram, productPanel, productDownload, location, activeBar, setActiveBar, inquireRef } = props;

  return (
    <div className="container mt-3" ref={inquireRef}>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <div
            className={`nav-link ${activeBar === "overview" ? "active" : "notactive"}`}
            onClick={() => setActiveBar("overview")}
          >
            Overview
          </div>
        </li>
        <li className="nav-item">
          <div
            className={`nav-link ${activeBar === "specs" ? "active" : "notactive"}`}
            onClick={() => setActiveBar("specs")}
          >
            Specs
          </div>
        </li>
        <li className="nav-item">
          <div
            className={`nav-link ${activeBar === "faq" ? "active" : "notactive"}`}
            onClick={() => setActiveBar("faq")}
          >
            FAQ
          </div>
        </li>
        {productDiagram.length !== 0 && (
          <li className="nav-item">
            <div
              className={`nav-link ${activeBar === "diagram" ? "active" : "notactive"}`}
              onClick={() => setActiveBar("diagram")}
            >
              Diagram
            </div>
          </li>
        )}
        {productPanel.length !== 0 && (
          <li className="nav-item">
            <div
              className={`nav-link ${activeBar === "panel" ? "active" : "notactive"}`}
              onClick={() => setActiveBar("panel")}
            >
              Panel
            </div>
          </li>
        )}
        {productDownload.length !== 0 && (
          <li className="nav-item">
            <div
              className={`nav-link ${activeBar === "downloads" ? "active" : "notactive"}`}
              onClick={() => setActiveBar("downloads")}
            >
              Downloads
            </div>
          </li>
        )}
        <li className="nav-item">
          <div
            className={`nav-link ${activeBar === "inquire" ? "active" : "notactive"}`}
            onClick={() => setActiveBar("inquire")}
          >
            Inquire Product
          </div>
        </li>
      </ul>

      <div className="tab-content mt-3">
        {activeBar === "inquire" && (
          <InquireProduct
            productDetails={{ mpn: data.sku, name: data.title }}
            location={location}
            inquireRef={inquireRef}
          />
        )}

        {activeBar === "overview" && <Overview data={data} />}

        {activeBar === "specs" && <Specs data={data} />}

        {activeBar === "faq" && <Faq data={data} />}

        {activeBar === "diagram" && productDiagram.length !== 0 && <Diagram data={productDiagram} />}

        {activeBar === "panel" && productPanel.length !== 0 && <Panel data={productPanel} />}

        {activeBar === "downloads" && productDownload.length !== 0 && <Downloads data={productDownload} />}
      </div>
    </div>
  );
};

export default ProductDetails;
