import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ListFreePrimary from "../../ui/list/ListFreePrimary";
import { connect } from "react-redux";
import { setLoadingTrue } from "../../actions/products";
import SidebarSecondary from "../common/sidebar/SidebarSecondary";
import { contact } from "../common/partials/navData";
import {
  emailValidation,
  noSpaceOnChange,
  notEmptyValidation,
  phoneOnChange,
  phoneValidation,
} from "../../validation/formValidation";
import { formStatusCustom, formStatusNull, formStatusPending, postInquiry } from "../../actions/forms";
import axios from "../../api";

const InquireProduct = (props) => {
  const { setLoadingTrue, location, formStatusCustom, formStatusNull, formStatusPending, postInquiry } = props;
  useEffect(() => {
    const product = JSON.parse(localStorage.getItem("product"));
    setProductDetails(product);
    localStorage.removeItem("product");

    return () => {
      setLoadingTrue();
    };
  }, []);

  const [productDetails, setProductDetails] = useState(null);

  const [inquiryData, setInquiryData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    phone: "",
    email: "",
    question: "",
    isNewsletter: false,
  });

  const [inquiryError, setInquiryError] = useState({
    firstNameError: "",
    lastNameError: "",
    companyError: "",
    phoneError: "",
    emailError: "",
    questionError: "",
    isNewsletterError: "",
  });

  const onChangeData = (e) => {
    if (e.target.name === "phone") {
      phoneOnChange(e.target.value) && setInquiryData({ ...inquiryData, phone: e.target.value });
    } else if (e.target.name === "email") {
      noSpaceOnChange(e.target.value) && setInquiryData({ ...inquiryData, email: e.target.value });
    } else {
      setInquiryData({ ...inquiryData, [e.target.name]: e.target.value });
    }
  };

  const onBlurInput = (e) => {
    console.log("inside product inquiry input")
    if (e.target.name === "phone" && !phoneValidation(inquiryData.phone)) {
      setInquiryError({ ...inquiryError, phoneError: "Number must have 10 digits" });
    }
    if (e.target.name === "email" && !emailValidation(inquiryData.email)) {
      setInquiryError({ ...inquiryError, emailError: "Please enter a valid email" });
    }
    if (e.target.name === "question" && !emailValidation(inquiryData.question)) {
      setInquiryError({ ...inquiryError, questionError: "Please enter your quuestion" });
    }
  };

  const onSubmitValidation = () => {
    if (
      notEmptyValidation(inquiryData.firstName) &&
      notEmptyValidation(inquiryData.lastName) &&
      notEmptyValidation(inquiryData.company) &&
      notEmptyValidation(inquiryData.question) &&
      emailValidation(inquiryData.email) &&
      phoneValidation(inquiryData.phone)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!onSubmitValidation()) {
      return;
    }
    formStatusPending();
    const body = {
      sku: productDetails.mpn,
      name: productDetails.name,
      company: inquiryData.company,
      phone: inquiryData.phone,
      reftype: null,
      email: inquiryData.email,
      inquiry: inquiryData.question,
    };

    try {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, { action: "submit" });

        const res = await axios.get("/api/Home/ValidateCaptcha", {
          params: {
            captcha: token,
          },
        });

        if (res.data) {
          const status = await postInquiry(body);
          if (status === 200)
            setTimeout(() => {
              formStatusNull();
            }, 3000);
        } else {
          formStatusCustom(404);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        }
      });
    } catch (error) {
      formStatusCustom(404);
      setTimeout(() => {
        formStatusNull();
      }, 3000);
    }
  };

  return (
    <>
      <Helmet>
        <title>{productDetails ? productDetails.name : ""} Product Inquiry</title>
      </Helmet>

      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={contact} title="Contact" />
        </div>
        <div className="inquire-product">
          <h1 className="display-4 mb-2">Product Inquiry</h1>
          {productDetails && (
            <>
              <p className="lead">
                <b>MPN: </b>
                {productDetails ? productDetails.mpn : ""}
              </p>
              <p className="lead mb-3">
                <b>Item: </b>
                {productDetails ? productDetails.name : ""}
              </p>
            </>
          )}


          <form class="flex flex-col items-center gap-10" style={{ marginTop: "20px" }} onSubmit={onSubmit}>
            <h1 className="display-center-5 fs-s text-center">Ask your question</h1>
            <div class="flex flex-col gap-10 p-20 w-full max-w-screen-lg bg-white rounded-md hover:shadow-xl transition-all ease-in-out">
              {/* <label htmlFor="inputFirstName">First Name</label> */}
              <input
                type="text"
                name="firstName"
                className="form-control"
                id="inputFirstName"
                value={inquiryData.firstName}
                onChange={onChangeData}
                placeholder="Enter your first name"
                required
              />
            </div>
            <div className="col-lg-6 form-group">
              {/* <label htmlFor="inputLastName">Last Name</label> */}
              <input
                type="text"
                name="lastName"
                className="form-control"
                id="inputLastName"
                value={inquiryData.lastName}
                onChange={onChangeData}
                placeholder="Enter your last name"
                required
              />
            </div>
            <div className="col-lg-6 form-group">
              {/* <label htmlFor="inputCompany">Company</label> */}
              <input
                type="text"
                name="company"
                className="form-control"
                id="inputCompany"
                value={inquiryData.company}
                onChange={onChangeData}
                placeholder="Enter your company"
                required
              />
            </div>
            <div className="col-lg-6 form-group">
              {/* <label htmlFor="inputPhone">Phone</label> */}
              <input
                type="text"
                name="phone"
                className="form-control"
                id="inputPhone"
                value={inquiryData.phone}
                onChange={onChangeData}
                onBlur={onBlurInput}
                placeholder="Enter your phone"
                required
              />
              {inquiryError.phoneError && <p className="invalid-data mt-1">{inquiryError.phoneError}</p>}
            </div>

            <div className="col-lg-6 form-group">
              {/* <label htmlFor="inputEmail">Email</label> */}
              <input
                type="text"
                name="email"
                className="form-control"
                id="inputEmail"
                value={inquiryData.email}
                onChange={onChangeData}
                onBlur={onBlurInput}
                placeholder="Enter your email"
                required
              />
              {inquiryError.emailError && <p className="invalid-data mt-1">{inquiryError.emailError}</p>}
            </div>
            <div className="form-group">
              {/* <label htmlFor="inputTextarea">Enter your inquiry or questions about this item here</label> */}
              <textarea
                className="form-control"
                id="inputTextarea"
                rows="3"
                name="question"
                value={inquiryData.question}
                onChange={onChangeData}
                onBlur={onBlurInput}
                placeholder="Type your question here..."
                required
              ></textarea>
              {inquiryError.questionError && <p className="invalid-data">{inquiryError.questionError}</p>}
            </div>
            <div className="form-group">
              <div className="form-check text-left">
                <input
                  className="form-check-input"
                  name="isNewsletter"
                  checked={inquiryData.isNewsletter}
                  value={inquiryData.isNewsletter}
                  onChange={onChangeData}
                  type="checkbox"
                  id="inputCheckbox"
                />
                <label className="form-check-label ml-4" htmlFor="inputCheckbox">
                  Yes, please send me latest information on KanexPro
                </label>
              </div>
            </div>

            <button size={1} type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default connect(null, { setLoadingTrue, formStatusPending, formStatusCustom, formStatusNull, postInquiry })(
  InquireProduct
);
