import React from "react";
import { Link } from "react-router-dom";
import "./button.scss";

const ButtonSecondary = ({ link, onClick, text, icon, customClass, customStyle }) => {
  if (link) {
    return (
      <Link className={`btn btn-secondary ${customClass}`} style={customStyle} to={link} onClick={onClick}>
        {icon && <i className={icon}></i>}
        {text}
      </Link>
    );
  } else {
    return (
      <button className={`btn btn-secondary ${customClass}`} style={customStyle} onClick={onClick}>
        {icon && <i className={icon}></i>}
        {text}
      </button>
    );
  }
};

export default ButtonSecondary;
