import React, { useEffect } from "react";
import DOMPurify from 'dompurify'
import { Link } from "react-router-dom";
import { getBlogDetails, setBlogLoadingTrue } from "../../../actions/products";
import { connect } from "react-redux";
import LoaderPrimary from "../../../ui/loader/LoaderPrimary";
import { Helmet } from "react-helmet";

const BlogDescription = (props) => {
  const { blogdetails } = props;
  useEffect(() => {
    props.getBlogDetails(props.match.params.Id);
    return () => {
      props.setBlogLoadingTrue();
    };
  }, []);
  if (props.blogloading) {
    return <LoaderPrimary />;
  }
  const canonicalUrl = `https://kanexpro.com/blog/description/${props.match.params.Id}`;
  
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="jumbotron Mediamain">
        <h3 className="display-4">{blogdetails.title} </h3>
        {/* <p>{newsdetails.newscontent}</p> */}
        <p className="content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blogdetails.content) }}></p>
        <br></br>
        <p>
          Press Inquiries please <Link to="/press">click here</Link>
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    blogdetails: state.data.blogdetails ? state.data.blogdetails : [],
    blogloading: state.data.blogloading,
  };
};

export default connect(mapStateToProps, { getBlogDetails, setBlogLoadingTrue })(BlogDescription);
