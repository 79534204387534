import React from "react";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";

const Overview = ({ data }) => {
  return (
    <div className="container fade-in">
      {/* <p className="mb-2">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua.
      </p> */}
      <ListFreePrimary customClass="overview">
        <div className="description">
          <h1 className="display-5 fs-m">Description</h1>

          <div dangerouslySetInnerHTML={{ __html: data.description }} />
        </div>
        <div className="features">
          <h1 className="display-center-5 fs-m">Features</h1>

          <div dangerouslySetInnerHTML={{ __html: data.features }} />
        </div>
      </ListFreePrimary>
    </div>
  );
};

export default Overview;
