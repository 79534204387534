export const solutionsId = {
  broadcast: 8,
  classroom: 1,
  corporate: 3,
  signage: 2,
  government: 6,
  hospitality: 10,
  worship: 9,
  residential: 7,
  retail: 4,
  healthcare: 5,
};
