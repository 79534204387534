import React from "react";
import { Helmet } from "react-helmet";
import AccordionPrimary from "../../../ui/accordion/AccordionPrimary";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import FaqForm from "../../forms/Faq";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import "./faq.scss";
import { resource } from "../../common/partials/navData";
import banner from "../images/banner.jpg";

const Faq = ({ location }) => {
  // const arr = ["a", "b", "c"];
  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={resource} title="Resources" />
        </div>
        <div className="faq-container">
          {/* <div className="banner">
            <img src={banner} alt="banner" />
            <div className="bg-dark" />
            <h1 className="display-4 mb-2">FAQ</h1>
          </div> */}
          <div className="faq">
            <h1 className="display-4 mb-2">FAQ</h1>
            {/* {arr.map((a, index) => (
              <AccordionPrimary
                id={index}
                que="This is dummy question?"
                ans="Lorem Ipsum is simply dummy text of the printing
        and typesetting industry. Lorem Ipsum has been the industry's
        standard dummy text ever since the 1500s, when an unknown printer
        took a galley of type and scrambled it to make a type specimen book.
        It has survived not only five centuries, but also the leap into electronic
         typesetting, remaining essentially unchanged. It was popularised in the 1960s
         with the release of Letraset sheets containing Lorem Ipsum passages, and more
         recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
              />
            ))} */}
            <ListFreePrimary customClass="mt-6">
              <FaqForm />
            </ListFreePrimary>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
