import React, { useEffect } from "react";

// import custom component
import PostOne from "../features/post/post-one";
import OwlCarousel from "../features/owl-carousel";

//import Slider Settings
import { blogSlider } from "../settings";

import { connect } from "react-redux";
import { getFeaturedProducts } from "../../actions/products";
import CarouselPrimary from "../../ui/carousel/CarouselPrimary";

//import Data
import posts_data from "../../mock_data/posts";

function BlogSection(props) {
  const { featuredProducts, getFeaturedProducts } = props;

  const posts = posts_data.slice(100, 103);

  useEffect(() => {
    getFeaturedProducts();
  }, []);

  return (
    <div className="blog-posts mb-5">
      <div className="">
        <h2 className="title text-center mb-4">Featured Products</h2>

        {/* <OwlCarousel adClass="owl-simple mb-3 carousel-with-shadow" carouselOptions={blogSlider}></OwlCarousel> */}
        <CarouselPrimary>
          {featuredProducts.map((item, index) => (
            <PostOne post={item} key={index} bodyClass="text-center pb-1" btnText="Read More" />
          ))}
        </CarouselPrimary>
      </div>
    </div>
  );
}

const mapStateToPrors = (state) => {
  return {
    featuredProducts: state.data.featuredProducts ? state.data.featuredProducts : [],
  };
};

export default connect(mapStateToPrors, { getFeaturedProducts })(BlogSection);
