import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import InputSearchSecondary from "../../../ui/input/InputSearchSecondary";
import { Link } from "react-router-dom";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import { account } from "../../common/partials/navData";
import "./open-items.scss";
import Comment from "./Comment";
import ButtonPrimary from "../../../ui/button/ButtonPrimary";
import { connect } from "react-redux";
import { getOpenItems } from "../../../actions/account";
import LoaderPrimary from "../../../ui/loader/LoaderPrimary";

const OpenItems = ({ location, openItems, getOpenItems, history, auth }) => {
  if (!auth.isAuthenticated) {
    history.push("/");
  }

  useEffect(() => {
    getOpenItems("C100578");
  }, []);

  const [displayOpenItems, setdisplayOpenItems] = useState([]);
  const [search, setsearch] = useState("");

  useEffect(() => {
    setdisplayOpenItems(openItems);
  }, [openItems]);

  useEffect(() => {
    if (search === "") {
      setdisplayOpenItems(openItems);
    } else {
      const filteredItems = openItems.filter(
        (item) =>
          item.Po.toLowerCase().includes(search.toLowerCase()) || item.sku.toLowerCase().includes(search.toLowerCase())
      );

      setdisplayOpenItems(filteredItems);
    }
  }, [search]);

  const [activeComment, setactiveComment] = useState({
    comment: "",
    docentry: null,
    linenum: null,
    product: null,
  });

  const [showComments, setShowComments] = useState(false);

  const scrollRef = useRef();
  const scrollTable = (direction, tableScrollRef) => {
    if (direction === "left") {
      tableScrollRef.current.scrollLeft -= 100;
    }
    if (direction === "right") {
      tableScrollRef.current.scrollLeft += 100;
    }
  };

  if (openItems.length === 0) {
    return <LoaderPrimary />;
  }

  return (
    <>
      <Helmet>
        <title>Open Items</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={account} title="Account" />
        </div>
        <div className="open-items">
          <h1 className="display-4 mb-3">Open Items</h1>
          <ListFreePrimary>
            <InputSearchSecondary customClass="mb-2" value={search} onChange={(e) => setsearch(e.target.value)} />
            <span>
              <span className="scroll-btn" onClick={() => scrollTable("left", scrollRef)}>
                ❮
              </span>{" "}
              Scroll{" "}
              <span className="scroll-btn" onClick={() => scrollTable("right", scrollRef)}>
                ❯
              </span>
            </span>

            <div ref={scrollRef} className="scroll-x">
              <table className="table">
                <thead className="row-brown">
                  <tr>
                    <th scope="col">PO</th>
                    <th scope="col">Sku</th>
                    <th scope="col">Order</th>
                    <th scope="col">Open</th>
                    <th scope="col">Stock</th>
                    <th scope="col">Ship From</th>
                    <th scope="col">Notes</th>
                    <th scope="col">Comment</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {displayOpenItems.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Link to={`/account/order/${item.SO}`}>{item.Po}</Link>
                      </td>
                      <td>{item.sku}</td>
                      <td>{item.Ordered}</td>
                      <td>{item.Open}</td>
                      <td>{item.Stock}</td>
                      <td>{item.whscode}</td>
                      <td>{item.Notes2}</td>
                      <td>{item.comment}</td>
                      <td>
                        <ButtonPrimary
                          onClick={() => {
                            setactiveComment({
                              comment: item.comment ? item.comment : "",
                              docentry: item.DocEntry,
                              linenum: item.LineNum,
                              product: item.sku,
                            });
                            setShowComments(!showComments);
                          }}
                          text="Comment"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </ListFreePrimary>
          {showComments && (
            <Comment
              data={activeComment}
              onClickCancel={() => setShowComments(false)}
              onClickBody={() => setShowComments(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    openItems: state.account.openItems ? state.account.openItems.refOpenItems : [],
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getOpenItems })(OpenItems);
