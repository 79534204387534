import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./carousel-primary.scss";

const CarouselPrimary = (props) => {
  const { children } = props;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group">
        <div className="arrow-left" onClick={() => previous()}>
          <i className="fas fa-chevron-left"></i>
        </div>
        <div className="arrow-right" onClick={() => next()}>
          <i className="fas fa-chevron-right"></i>
        </div>
      </div>
    );
  };

  return (
    <Carousel
      arrows={false}
      customButtonGroup={<ButtonGroup />}
      swipeable={true}
      draggable={false}
      showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={5000}
      keyBoardControl={true}
      //   customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-primary"
      removeArrowOnDeviceType={["mobile"]}
      // deviceType={props.deviceType}
      //   dotListClass="custom-dot-list-style"
      //   itemClass="carousel-item-padding-40-px"
    >
      {children}
    </Carousel>
  );
};

export default CarouselPrimary;
