import * as types from "../constants/action-types";
import axios from "../api/index";

export const getProductList = (cmd, bpid) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const accountId = getState().auth.userInfo.Account;
  try {
    const res = await axios.post(
      "/api/Account/GetProductsList",
      {
        cmd: cmd,
        bpid: accountId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({ type: types.PRODUCT_LIST, payload: res.data.refOpenOrdersPortal });
  } catch (error) {
    dispatch({
      type: types.USER_ERROR,
      payload: "error",
    });
  }
};

export const getSkuHistoryList = (cmd, bpid) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const accountId = getState().auth.userInfo.Account;
  try {
    const res = await axios.post(
      "/api/Account/GetSkuHistoryList",
      {
        cmd: cmd,
        bpid: accountId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({ type: types.SKUHISTORY_LIST, payload: res.data.refOpenOrdersPortal });
  } catch (error) {
    dispatch({
      type: types.USER_ERROR,
      payload: "error",
    });
  }
};

export const getOrderOpenHistory = (bpid) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const accountId = getState().auth.userInfo.Account;
  try {
    const res = await axios.post(
      "/api/Account/SAP_OrdersPortal",
      {
        bpid: accountId,
        status: "o",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({ type: types.ORDER_HISTORY_OPEN, payload: res.data.refSapOrdersPortal });
  } catch (error) {
    dispatch({
      type: types.USER_ERROR,
      payload: "error",
    });
  }
};

export const getOrderCloseHistory = (bpid) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const accountId = getState().auth.userInfo.Account;

  try {
    const res = await axios.post(
      "/api/Account/SAP_OrdersPortal",
      {
        bpid: accountId,
        status: "c",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({ type: types.ORDER_HISTORY_CLOSE, payload: res.data.refSapOrdersPortal });
  } catch (error) {
    dispatch({
      type: types.USER_ERROR,
      payload: "error",
    });
  }
};

export const getShipmentsList = (bpid) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const accountId = getState().auth.userInfo.Account;
  try {
    const res = await axios.post(
      "/api/Account/SAP_OpenOrdersPortal",
      {
        src: accountId,
        cmd: "delivery",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({ type: types.SHIPMENTS_LIST, payload: res.data.refSapPortal });
  } catch (error) {
    dispatch({
      type: types.USER_ERROR,
      payload: "error",
    });
  }
};

export const getShipmentDetails = (oid, bpid) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const accountId = getState().auth.userInfo.Account;
  try {
    const res = await axios.post(
      "/api/Account/GetShipmentDetails",
      {
        oid: oid,
        bpid: accountId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({ type: types.SHIPMENT_DETAILS, payload: res.data });
  } catch (error) {
    dispatch({
      type: types.USER_ERROR,
      payload: "error",
    });
  }
};

export const getOpenItems = (bpid) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const accountId = getState().auth.userInfo.Account;

  try {
    const res = await axios.post(
      "/api/Account/SAP_GetOpenItems",
      {
        sap: 2,
        bpid: accountId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({ type: types.OPEN_ITEMS, payload: res.data });
  } catch (error) {
    dispatch({
      type: types.USER_ERROR,
      payload: "error",
    });
  }
};

export const postOpenItemComments = (comment, docentry, linenum, product) => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    const res = await axios.post(
      "/api/Account/UpdateComments",
      {
        docentry: docentry.toString(),
        comment,
        linenum: linenum.toString(),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({ type: types.OPEN_ITEMS_COMMENT, payload: res.status });

    const openItems = JSON.parse(JSON.stringify(getState().account.openItems.refOpenItems));
    for (let i = 0; i < openItems.length; i++) {
      if (openItems[i].sku === product) {
        openItems[i].comment = comment;
      }
    }

    dispatch({ type: types.OPEN_ITEMS, payload: { refOpenItems: openItems } });
  } catch (error) {
    dispatch({
      type: types.USER_ERROR,
      payload: "error",
    });
  }
};

export const openItemsCommentStatus = (isStatus) => async (dispatch) => {
  dispatch({ type: types.OPEN_ITEMS_COMMENT, payload: isStatus });
};

export const setAccountLoadingTrue = () => async (dispatch) => {
  dispatch({ type: types.ACCOUNT_TRUE });
};
