import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getFeaturedProducts } from "../../actions/products";
import { Link } from "react-router-dom";
import "./style.scss";
import ButtonPrimary from "../../ui/button/ButtonPrimary";

const ProductsSection = (props) => {
  const { featuredProducts, getFeaturedProducts } = props;

  useEffect(() => {
    getFeaturedProducts();
  }, []);

  return (
    <div className="products-section">
      <h2 className="title text-center mb-4">Featured Products</h2>
      <div className="products">
        {featuredProducts.map((product) => (
          <div className="card-container">
            <div className="card">
              <img src={product.kproimage} alt={product.title} />
              <p>{product.title}</p>
              <ButtonPrimary link={`${process.env.PUBLIC_URL}/item/${product.sku}`} text="More Info" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToPrors = (state) => {
  return {
    featuredProducts: state.data.featuredProducts ? state.data.featuredProducts : [],
  };
};

export default connect(mapStateToPrors, { getFeaturedProducts })(ProductsSection);
