import React from 'react'
import "./Work.css"
import KanexProImg from "../WorkFromHome/Workimg/KanexPro_WFH_LandingPage_Slice-1.jpg";
import { Link } from 'react-router-dom';
import Work2 from "../WorkFromHome/Workimg/Work2.jpg"
import Work3 from "../WorkFromHome/Workimg/Work3.jpg"
import Work4 from "../WorkFromHome/Workimg/Work4.jpg"
import Work5 from "../WorkFromHome/Workimg/Work5.jpg"
import Work6 from "../WorkFromHome/Workimg/Work6.jpg"

export default function Work() {
    return (
        <>

         <div className="container-fluid d-flex flex-column justify-content-center mt-3" style={{backgroundColor:'white'}}>
             
             <img src={KanexProImg} alt="img1"/>

             <Link className=" d-flex flex-column justify-content-center" to="/item/SW-2X14KUSBC">
             <img src={Work2} alt="img2"/>
             </Link>

             <Link  className=" d-flex flex-column justify-content-center" to="/item/HAECOAX3">
             <img src={Work3} alt="img3"/>
             </Link>

             <Link  className=" d-flex flex-column justify-content-center" to="/item/SP-HD1X24K">
             <img src={Work4} alt="img4"/>
             </Link>

             <Link  className=" d-flex flex-column justify-content-center" to="/item/SW-4X1SL18G">
             <img src={Work5} alt="img5"/>
             </Link>

             <Link  className=" d-flex flex-column justify-content-center" to="/item/CBL-HDMICERTSS6FT">
             <img src={Work6} alt="img6"/>
             </Link>

         </div>

        </>
    )
}
