import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import InputSearchSecondary from "../../../ui/input/InputSearchSecondary";
import { Link } from "react-router-dom";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import { account } from "../../common/partials/navData";
import { connect } from "react-redux";
import { getSkuHistoryList } from "../../../actions/account";
import { getProductDetails, addToCart, emptyProductDetails } from "../../../actions/products";
import "./sku-history.scss";
import { uptoTwwoDecimal } from "../../../reusable-functions/calculations";
import ButtonSecondary from "../../../ui/button/ButtonSecondary";
import LoaderPrimary from "../../../ui/loader/LoaderPrimary";

const SkuHistory = (props) => {
  const { location, history, auth, productStocks } = props;

  if (!auth.isAuthenticated) {
    history.push("/");
  }

  const {
    getSkuHistoryList,
    skuHistoryList,
    getProductDetails,
    productDetails,
    addToCart,
    cart,
    emptyProductDetails,
  } = props;

  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);
  const stockRef = useRef();
  const firstRender = useRef(true);

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (search === "") {
      setList(skuHistoryList);
    } else {
      const filteredList = skuHistoryList.filter(
        (item) =>
          item.gname.toLowerCase().includes(search.toLowerCase()) ||
          item.Sku.toLowerCase().includes(search.toLowerCase())
      );
      setList(filteredList);
    }
  }, [search]);

  useEffect(() => {
    setList(skuHistoryList);
  }, [skuHistoryList]);

  console.log(productStocks);

  function myPrice() {
    const stock = productStocks[0];
    if (auth.userInfo.AccountType === "Dealer") {
      return uptoTwwoDecimal(stock.dealer);
    } else if (auth.userInfo.AccountType === "Distributor") {
      return uptoTwwoDecimal(stock.direct);
    } else if (auth.userInfo.AccountType === "DealerInt") {
      return uptoTwwoDecimal(stock.dealer2);
    } else if (auth.userInfo.AccountType === "DistributorInt") {
      return uptoTwwoDecimal(stock.direct2);
    } else {
      return uptoTwwoDecimal(stock.msrp);
    }
  }

  const renderList = () => {
    return list.map((item, index) => (
      <tr key={index}>
        <td>{item.gname}</td>
        <td>
          <Link to={`/item/${item.Sku}`}>{item.Sku}</Link>
        </td>
        <td>{item.ship}</td>
        <td>{item.Stock}</td>
        <td>
          {cart.find((cartItem) => cartItem.id === item.Sku) ? (
            <div className="d-flex justify-content-center">
              <p className="mr-3 green">Added to cart</p>
              <i className="fas fa-check fs-m green"></i>
            </div>
          ) : item.Stock <= 0 ? (
            <div className="out-stock">Out of stock</div>
          ) : (
            <ButtonSecondary
              onClick={() => {
                stockRef.current = item.Stock;
                addProductToCart(item.Sku);
              }}
              text="Add to Cart"
              icon="fas fa-cart-plus"
            />
          )}
        </td>
      </tr>
    ));
  };

  useEffect(() => {
    getSkuHistoryList("skuhistory", "C100578");

    return () => {
      emptyProductDetails();
    };
  }, []);

  const scrollRef = useRef();
  const scrollTable = (direction, tableScrollRef) => {
    if (direction === "left") {
      tableScrollRef.current.scrollLeft -= 100;
    }
    if (direction === "right") {
      tableScrollRef.current.scrollLeft += 100;
    }
  };

  const addProductToCart = async (skuid) => {
    await getProductDetails(skuid);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (productDetails.length !== 0) {
      const item = productDetails[0];
      const product = {
        id: item.sku,
        title: item.title,
        subtitle: item.subtitle,
        img: item.image1 ? item.image1 : item.image2 ? item.image2 : item.image3,
        amount: myPrice(),
        qty: +1,
        stock: stockRef.current,
      };
      addToCart(product);
    }
  }, [productDetails[0]]);

  if (skuHistoryList.length === 0) {
    return <LoaderPrimary />;
  }

  return (
    <>
      <Helmet>
        <title>Sku History</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={account} title="Account" />
        </div>
        <div className="sku-history">
          <h1 className="display-4 mb-3">Order Sku History</h1>
          <ListFreePrimary>
            <InputSearchSecondary customClass="mb-2" value={search} onChange={onChange} />
            <span className="d-block">
              <span className="scroll-btn" onClick={() => scrollTable("left", scrollRef)}>
                ❮
              </span>{" "}
              Scroll{" "}
              <span className="scroll-btn" onClick={() => scrollTable("right", scrollRef)}>
                ❯
              </span>
            </span>

            <div ref={scrollRef} className="scroll-x">
              <table className="table">
                <thead className="row-brown">
                  <tr>
                    <th scope="col">Category</th>
                    <th scope="col">Skew</th>
                    <th scope="col">Shipped</th>
                    <th scope="col">stock</th>
                    <th scope="col">Add to cart</th>
                  </tr>
                </thead>

                <tbody>{renderList()}</tbody>
              </table>
            </div>
          </ListFreePrimary>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    skuHistoryList: state.account.skuHistoryList ? state.account.skuHistoryList : [],
    productDetails: state.data.productDetails.refItemDetail ? state.data.productDetails.refItemDetail : [],
    productStocks: state.data.productDetails.refStocks ? state.data.productDetails.refStocks : [],
    cart: state.cart.cart ? state.cart.cart : [],
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getSkuHistoryList, getProductDetails, addToCart, emptyProductDetails })(
  SkuHistory
);
