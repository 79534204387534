import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";

const LazyLoadImg = ({ image, style }) => {
  return (
    <div>
      <LazyLoadImage
        //effect="blur"
        threshold={500}
        alt={image.alt}
        src={image.src} // use normal <img> attributes as props
        width={image.width}
        height={image.height}
        className={image.className}
        id={image.id}
      />
    </div>
  );
};

export default LazyLoadImg;
