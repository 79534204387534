import React, { useEffect } from 'react';
import Aos from 'aos';
import AvsolImg from '../AvSolution/images/SDVOE_Header-banner-01.jpg';
import AvsolImg2 from '../AvSolution/images/SDVOE_Connect-Chart-02.jpg';
import AvsolImg3 from '../AvSolution/images/SDVOE_VideoWall-03.jpg';
import AvsolImg4 from '../AvSolution/images/SDVOE_otherproducts-05.jpg';
import AvsolImg5 from '../AvSolution/images/SDVOE_Bottom_icons-06.jpg';
import "aos/dist/aos.css";
import "./AvSol.css";



const AvSol = () => {
    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])
    return (
        <div className="container-fluid" >
            <div className="container-fluid" style={{marginTop:'20px'}}>
                <img className="FirstImg" src={AvsolImg} alt="imageAvSol" />
            </div>
            <div className=" " style={{ marginTop: '20px' }}>
                <p className="lead  textAv ">
                    The KanexPro SDVoE powered Modular Matrix system is the most versatile flexible video distribution solution. <br></br>The system is composed of transceiver devices that can be configured as either video transmitters or receivers with a simple click of a mouse. <br></br>The whole system is controlled and configured by a single control module which makes setup a snap.  </p>
                <br></br>

                <h5 className="display-6 d-flex justify-content-center">EASY DEPOLYMENT & FUNCTIONALITY</h5>
                <p className="lead  textAv">
                    Create one to one, one to many, or many to many by<br></br>
                    simply adding the encoders<br></br>
                    and decoders to your network switch<br></br>
                </p>
            </div>
            <div className=" container-fluid">
                <img className="SecondImg" src={AvsolImg2} alt="image" />
            </div>
            <div className="" style={{ marginTop: '20px' }}>
                <h5 className="display-6 d-flex justify-content-center">VIDEO WALL APPLICATION & MULTI-VIEW</h5>
                <p className="lead  textAv ">
                    Powerful creation of video walls and multi-viewing for
                    mission critical environment
                </p>
            </div>
            <div className="">
                <img src={AvsolImg3} alt="img3" />
            </div>
            <div className=""   style={{ marginTop: '20px' }}>
                <p className="lead  textAv " >
                Create a 3x3 video wall or multi-view by adding multiple<br></br>
decoders to your application. They instantly
display and have lossless video for critical video applications.<br></br>
They can all be controlled by a
central web base GUI.<br></br>
                </p>
            </div>
            <div>
                <a href="/networkav"><img src={AvsolImg4} alt="img67"/></a>
            </div>
            <div className=""   style={{ marginTop: '20px',marginBottom:'30px' }}>
                
            <a href="mailto:sales@kanexpro.com?subject=Network AV inquiry"><h6 className=" textAv " > Send  Inquiries to sales@kanexpro.com</h6></a>
            <img src={AvsolImg5} alt="img678"/>
                </div>
        </div>

    );
};
export default AvSol;