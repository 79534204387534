import { urlValidation } from "../../../validation/formValidation";

// Billing Validations

export const companyValidation = (company) => {
  if (company) {
    return true;
  } else {
    return false;
  }
};

export const billingWebsiteValidation = (website) => {
  if (!website || urlValidation(website)) {
    return true;
  } else {
    return false;
  }
};

export const billingPhoneValidation = (phone) => {
  if (!phone || phone.length === 10) {
    return true;
  } else {
    return false;
  }
};

// Shipping Validations
