import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

function Footer(props) {
  const { logoFooter = "assets/images/home/KanexPro_Logo_Wht.svg" } = props;
  const { container = "container" } = props;

  return (
    <footer className="footer">
      <div className="footer-middle">
        <div className={container}>
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="widget widget-about">
                <h4 className="widget-title">about KanexPro</h4>
                <p>
                  KanexPro carries a complete selection of A/V connectivity needs. Our mission is always to create
                  top-tier technology reliable and affordable.
                </p>

                <div className="social-icons">
                  <a
                    href="https://www.facebook.com/kanexpro"
                    className="social-icon social-facebook"
                    rel="noopener noreferrer"
                    title="Facebook"
                    target="_blank"
                  >
                    <i className="icon-facebook-f"></i>
                  </a>
                  <a
                    href="https://twitter.com/kanexpro"
                    className="social-icon social-twitter"
                    rel="noopener noreferrer"
                    title="Twitter"
                    target="_blank"
                  >
                    <i className="icon-twitter"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/kanexpro?trk=biz-companies-cym"
                    className="social-icon social-pinterest"
                    rel="noopener noreferrer"
                    title="Linkedin"
                    target="_blank"
                  >
                    <i className="icon-linkedin"></i>
                  </a>
                  <a
                    href="https://www.kanexpro.com/contact/"
                    className="social-icon social-instagram"
                    rel="noopener noreferrer"
                    title="Contact"
                    target="_blank"
                  >
                    <i class="fas fa-envelope"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">Useful Links</h4>

                <ul className="widget-list">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/new`}>New products</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/support`}>Tech support</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/sales`}>Sales Inquiry</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/contact`}>Contact us</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/warranty`}>Warranty</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/flexmatrix`}>Flex Matrix</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/aoc`}>AOC</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/catalog`}>Catalog</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">Company</h4>

                <ul className="widget-list">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/media`}>News</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/faq`}>Faq</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/casestudy`}>Case Studies</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/warranty/CAProp65`}>CA Prop 65</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/signup`}>Newsletter Signup</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">More Info</h4>

                {/* <ul className="widget-list">
                  <li>
                    <a
                      href="https://www.kanexpro.com/catalog/KanexPro_Catalog_AUG_2020_V1.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Catalog
                    </a>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/warranty`}>Warranty</Link>
                  </li>
                </ul> */}

                <p className="mb-1">
                  <span style={{ color: "#fff", fontWeight: "bold" }}>Address:</span>
                  <br />
                  3753 Howard Hughes Parkway, Suite 200-1083, Las Vegas, Nevada 89169
                </p>

                <p>
                  <span style={{ color: "#fff", fontWeight: "bold" }}>Business Hours:</span>
                  <br />
                  Monday – Friday: 6:00 am – 5:00 pm PST Weekends & Holidays – Closed
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className={container}>
          {/* <figure className="footer-payments">
                        <img src={ `${process.env.PUBLIC_URL}/assets/images/payments.png` } alt="Payment methods" width="272" height="20" />
                    </figure> */}
          <img src={process.env.PUBLIC_URL + "/" + logoFooter} alt="Kanex Logo" width={200} height={30} />
          <p className="footer-copyright mb-1">
            Copyright © {new Date().getFullYear()} Kanex Pro. All Rights Reserved.
          </p>
          <a href="tel:8889751368">Sales & Support: +1 (888) 975-1368</a>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
