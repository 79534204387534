import React, { useEffect, useState } from 'react'

const Mypagination = ({showperpage, onPaginationChange,total }) => {
  

    const [counter,setCounter]=useState(1)
    const [numberofButtons , setNumberofButtons] = useState(Math.ceil(total/showperpage))

    const onButtonClick =(type)=>{
        if (type === "previous"){
              if(counter === 1){
                  setCounter(1)
              }else{
                  setCounter(counter - 1)
              }
        }
        else if (type === "next"){
            if( numberofButtons === counter){
                setCounter(counter);
            }else{
                setCounter(counter + 1)
            }
        }
    }



useEffect(()=>{
    const value =showperpage * counter;

    onPaginationChange (value - showperpage, value)
},[counter])

    return (
        <div className="d-flex flex-row justify-content-center align-items-center">

<nav aria-label="...">
  <ul class="pagination">
    <li class="page-item ">
      <button class="  page-link" href="!#" tabindex="-1"  onClick={()=>onButtonClick("previous")}>Previous</button>
    </li>
    
  {
      new Array(numberofButtons).fill("").map((el,index)=>(
        <li className={`page-item ${index + 1 === counter ?  "active ":null}`}>
        <button class="page-link" onClick={()=>setCounter(index+1)}>{index + 1}</button></li>
      ))
  }
    <li class="page-item">
      <button class="  page-link" href="!#" onClick={()=>onButtonClick("next")} >Next</button>
    </li>
  </ul>
</nav>



            {/* <button className="btn btn-primary" onClick={()=>onButtonClick("previous")}>Previous</button>
            <button className="btn btn-primary" onClick={()=>onButtonClick("next")}>Next</button>
             */}
        </div>
    )
}

export default Mypagination
