import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getNewProducts, setLoadingTrue } from "../../actions/products";
import CardPrimary from "../../ui/CardPrimary";
import PaginationSecondary from "../../ui/pagination/PaginationSecondary";
import { products } from "../common/partials/navData";
import "./new-products.scss";
import Sidebar from "./Sidebar";
import { Helmet } from "react-helmet";


const NewProducts = (props) => {
  const { newProducts, getNewProducts, setLoadingTrue } = props;

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = newProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    getNewProducts();

    return () => {
      setLoadingTrue();
    };
  }, []);

  return (
    <div className="d-flex">
      <Helmet>
        <link rel="canonical" href="https://kanexpro.com/new"/>
      </Helmet>
      <div className="d-none d-lg-block d-xl-block">
        {/* Passing routes to and data to sidebar for styling and interactive purposes */}
        <Sidebar data={products} path={props.location.pathname} />
      </div>

      <div className="new-products">
        <h1 className="display-4">New Products</h1>
        {currentProducts.map((product) => (
          <CardPrimary
            key={product.pkskuid}
            image={product.kproimage}
            title={product.title}
            subtitle={product.kprodesc}
            mpn={product.sku}
            link={`/item/${product.sku}`}
          />
        ))}
        <PaginationSecondary
          productsPerPage={productsPerPage}
          totalProducts={newProducts.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    newProducts: state.data.newProducts ? state.data.newProducts : [],
  };
};

export default connect(mapStateToProps, { getNewProducts, setLoadingTrue })(NewProducts);
