import * as types from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  user: null,
  userInfo: null,
  userLoading: true,
  userError: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        userLoading: false,
        userError: null,
      };
    case types.LOGIN:
      localStorage.setItem("token", JSON.stringify(action.payload.token));
      return {
        ...state,
        isAuthenticated: true,
        userLoading: false,
        token: action.payload.token,
        user: action.payload,
        userError: null,
      };
    case types.USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case types.LOGOUT:
    case types.USER_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        userLoading: false,
        user: null,
        userError: action.payload,
      };
    case types.CLEAR_ERROR:
      return {
        ...state,
        userError: "",
      };

    default:
      return state;
  }
};
const persistConfig = {
  keyPrefix: "molla-",
  key: "auth",
  storage,
};

export default persistReducer(persistConfig, authReducer);
