import React, { useEffect } from "react";
import sidebarAnimation from "./sidebarAnimation";
import "./sidebar-secondary.scss";
import { Link } from "react-router-dom";

const SidebarSecondary = ({ path, data, title }) => {
  const pathReform = path && path.slice(-1) === "/" ? path.slice(0, -1) : path; // value  -> /path/subpath

  useEffect(() => {
    sidebarAnimation();
  }, []);

  const renderMenuItems = () => {
    return data.map((product, index) => (
      <li className="sidebar-dropdown" key={index}>
        <Link className="sidebar-secondary" to={product.name ? product.path : "#"} id="menu">
          <span className={pathReform && product.path === pathReform ? "custom-active" : ""}>{product.name}</span>
        </Link>
      </li>
    ));
  };

  return (
    <div className="sidebar-secondary-container">
      <div className="page-wrapper chiller-theme toggled">
        <nav id="sidebar" className="sidebar-wrapper">
          <div className="sidebar-content">
            <p className="sidebar-secondary-title">{title}</p>

            <div className="sidebar-menu">
              <ul>
                <li className="header-menu">
                  <span>General</span>
                </li>

                {renderMenuItems()}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SidebarSecondary;
