import React, { useState } from "react";
import FormInput from "../FormInput";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import {
  emailValidation,
  noSpaceOnChange,
  notEmptyValidation,
  phoneOnChange,
  phoneValidation,
} from "../../../validation/formValidation";
import { connect } from "react-redux";
import { postPress, formStatusPending, formStatusNull, formStatusCustom } from "../../../actions/forms";
import FeedbackPrimary from "../../../ui/feedback/FeedbackPrimary";
import axios from "../../../api";

function PressInq(props) {
  const { postPress, formStatusPending, formStatusNull, formStatus, formStatusCustom } = props;

  const [pressForm, setPressForm] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    inquiry: "",
    isNewsletter: false,
  });

  const [pressError, setPressError] = useState({
    nameError: "",
    emailError: "",
    phoneError: "",
    companyError: "",
    inquiryError: "",
  });

  const onChangeData = (e) => {
    if (e.target.name === "isNewsletter") {
      setPressForm({ ...pressForm, isNewsletter: !pressForm.isNewsletter });
    } else if (e.target.name === "email") {
      noSpaceOnChange(e.target.value) && setPressForm({ ...pressForm, email: e.target.value });
    } else if (e.target.name === "phone") {
      phoneOnChange(e.target.value) && setPressForm({ ...pressForm, phone: e.target.value });
    } else {
      setPressForm({ ...pressForm, [e.target.name]: e.target.value });
    }
  };

  const onBlurInput = (e) => {
    if (e.target.name === "email" && !emailValidation(pressForm.email)) {
      setPressError({ ...pressError, emailError: "Please enter a valid email" });
    }
    if (e.target.name === "phone" && !phoneValidation(pressForm.phone)) {
      setPressError({ ...pressError, phoneError: "Phone number should be of 10 digits" });
    }
    if (e.target.name === "name" && !notEmptyValidation(pressForm.name)) {
      setPressError({ ...pressError, nameError: "First name is required" });
    }
    if (e.target.name === "company" && !notEmptyValidation(pressForm.company)) {
      setPressError({ ...pressError, companyError: "Company is required" });
    }
    if (e.target.name === "inquiry" && !notEmptyValidation(pressForm.inquiry)) {
      setPressError({ ...pressError, inquiryError: "Inquiry is required" });
    }
  };

  const onSubmitValidation = () => {
    if (
      notEmptyValidation(pressForm.name) &&
      notEmptyValidation(pressForm.company) &&
      notEmptyValidation(pressForm.inquiry) &&
      emailValidation(pressForm.email) &&
      phoneValidation(pressForm.phone)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!onSubmitValidation()) {
      return;
    }
    formStatusPending();
    try {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, { action: "submit" });

        const res = await axios.get("/api/Home/ValidateCaptcha", {
          params: {
            captcha: token,
          },
        });

        if (res.data) {
          setPressForm({
            name: "",
            email: "",
            phone: "",
            company: "",
            inquiry: "",
            isNewsletter: false,
          });
          setPressError({
            nameError: "",
            emailError: "",
            phoneError: "",
            companyError: "",
            inquiryError: "",
          });

          const status = await postPress(pressForm);
          if (status === 200)
            setTimeout(() => {
              formStatusNull();
            }, 3000);
        } else {
          formStatusCustom(404);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        }
      });
    } catch (error) {
      formStatusCustom(404);
      setTimeout(() => {
        formStatusNull();
      }, 3000);
    }
  };

  return (
    <div className="PressMainContainer">
      <div class="login d-flex align-items-center py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-xl-12 mx-auto text-center">
              <h1 class="display-center-5">Fill out this form</h1>
              <form className="flex flex-col items-center gap-10" style={{ marginTop: "20px" }} onSubmit={onSubmit}>
                <div class="flex flex-col gap-10 p-20 w-full max-w-screen-lg bg-white rounded-md hover:shadow-xl transition-all ease-in-out">
                  <FormInput
                    title="Name"
                    type="text"
                    placeholder="Enter name"
                    required="true"
                    name="name"
                    value={pressForm.name}
                    onChange={onChangeData}
                    onBlur={onBlurInput}
                    error={pressError.nameError}
                  />
                  <FormInput
                    title="Company"
                    type="text"
                    placeholder="Enter company"
                    required=""
                    name="company"
                    value={pressForm.company}
                    onChange={onChangeData}
                    onBlur={onBlurInput}
                    error={pressError.companyError}
                  />
                  <FormInput
                    title="Email"
                    type="text"
                    placeholder="Enter email"
                    required="true"
                    name="email"
                    value={pressForm.email}
                    onChange={onChangeData}
                    onBlur={onBlurInput}
                    error={pressError.emailError}
                  />
                  <FormInput
                    title="Phone"
                    type="text"
                    placeholder="Enter phone number"
                    required=""
                    name="phone"
                    value={pressForm.phone}
                    onChange={onChangeData}
                    onBlur={onBlurInput}
                    error={pressError.phoneError}
                  />

                  <div className="">
                    <textarea
                      placeholder="You Should Ask Question Here..."
                      class="pb-cmnt-textarea"
                      id="text"
                      name="inquiry"
                      rows="5"
                      value={pressForm.inquiry}
                      onChange={onChangeData}
                      onBlur={onBlurInput}
                    ></textarea>
                    {pressError.inquiryError && <p className="invalid-data mt-1">{pressError.inquiryError}</p>}
                  </div>
                  <div class="check">
                    <input
                      class="check-input"
                      type="checkbox"
                      name="isNewsletter"
                      value={pressForm.isNewsletter}
                      onChange={onChangeData}
                      id="flexCheckDefault"
                    />
                    <label class="check-label" for="flexCheckDefault">
                      Yes, please send me newsletter on KanexPro
                    </label>
                  </div>
                  <button class="btn btn-primary float-xs-right" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {formStatus && <FeedbackPrimary formStatus={formStatus} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    formStatus: state.data.formStatus,
  };
};

export default connect(mapStateToProps, { postPress, formStatusPending, formStatusNull, formStatusCustom })(PressInq);
