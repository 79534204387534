import React, { useState, useEffect } from "react";
import ButtonPrimary from "../../ui/button/ButtonPrimary";
import warrantyLogo from "./images/warranty-logo.png";
import fourK from "./images/4k.png";
import ButtonQuantity from "../../ui/button/ButtonQuantity";
import ButtonSecondary from "../../ui/button/ButtonSecondary";
import LazyLoadImg from "../../ui/lazyLoadImg";

import ReactImageZoom from "react-image-zoom";
import { connect } from "react-redux";
import { addToCart, removeFromCart } from "../../actions/products";
import { noSpaceOnChange, numberValidation } from "../../validation/formValidation";
import { uptoTwwoDecimal } from "../../reusable-functions/calculations";
import ImageView from "./ImageView";

const ProductDisplay = (props) => {
  const { data, auth, addToCart, cart, stock, setActiveBar, inquireRef } = props;

  const initialImgDisplay = data.image1 ? data.image1 : data.image2 ? data.image2 : data.image3;

  const [imgDisplay, setImgDisplay] = useState(initialImgDisplay);
  const [quantity, setQuantity] = useState(1);
  const [imgView, setImgView] = useState(false);

  const properties = {
    width: 500,
    height: 300,
    zoomWidth: 500,
    img: imgDisplay,
    zoomPosition: "original",
  };

  const addProductToCart = () => {
    const product = {
      id: data.sku,
      title: data.title,
      subtitle: data.subtitle,
      img: data.image1 ? data.image1 : data.image2 ? data.image2 : data.image3,
      amount: myPrice(),
      qty: +quantity,
      stock: stock.stock,
    };
    addToCart(product);
  };

  function msrpPrice() {
    if (auth.userInfo.AccountType === "Dealer") {
      return uptoTwwoDecimal(stock.msrp);
    } else if (auth.userInfo.AccountType === "Distributor") {
      return uptoTwwoDecimal(stock.msrp);
    } else if (auth.userInfo.AccountType === "DealerInt") {
      return uptoTwwoDecimal(stock.msrp2);
    } else if (auth.userInfo.AccountType === "DistributorInt") {
      return uptoTwwoDecimal(stock.msrp2);
    } else {
      return uptoTwwoDecimal(stock.msrp);
    }
  }

  function myPrice() {
    // dealer: 157.49
    // dealer2: 165.6
    // direct: 146.99
    // direct2: 132.48
    // distiADI: 146.99
    // intlold: 0
    // msrp: 262.49
    // msrp1: 262.49
    // msrp2: 276
    // msrpint: 276
    // stock: 7
    if (auth.userInfo.AccountType === "Dealer") {
      return uptoTwwoDecimal(stock.dealer);
    } else if (auth.userInfo.AccountType === "Distributor") {
      return uptoTwwoDecimal(stock.direct);
    } else if (auth.userInfo.AccountType === "DealerInt") {
      return uptoTwwoDecimal(stock.dealer2);
    } else if (auth.userInfo.AccountType === "DistributorInt") {
      return uptoTwwoDecimal(stock.direct2);
    } else {
      return uptoTwwoDecimal(stock.msrp);
    }
  }
  return (
    <div className="row">
      <div className="col-lg-6 col-md-12 d-flex flex-column align-items-lg-end align-items-center mb-4">
        <div className="d-flex flex-column">
          <div className="mb-3 product-image" onClick={() => setImgView(true)}>
            <ReactImageZoom {...properties} />
          </div>

          <div className="d-flex align-self-center">
            {data.image1 && (
              <img
                src={data.image1}
                alt="cable"
                className={`product-image-small ${data.image1 === imgDisplay && "img-active"}`}
                onClick={() => setImgDisplay(data.image1)}
              />
            )}
            {data.image2 && (
              <img
                src={data.image2}
                alt="cable"
                className={`product-image-small ${data.image2 === imgDisplay && "img-active"}`}
                onClick={() => setImgDisplay(data.image2)}
              />
            )}
            {data.image3 && (
              <img
                src={data.image3}
                alt="cable"
                className={`product-image-small ${data.image3 === imgDisplay && "img-active"}`}
                onClick={() => setImgDisplay(data.image3)}
              />
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 justify-around">
        <div className="product-info">
          <h1 className="display-4">{data.title}</h1>
          <p className="lead">{data.subtitle}</p>
          <div className="data-container mb-2">
            <p className="mpn mr-5">
              <b>MPN: </b>
              {data.sku}
            </p>
            <p className="upc">
              <b className="">UPC: </b>
              {data.upc}
            </p>
          </div>
          {auth.isAuthenticated &&
            (cart.find((item) => item.id === data.sku) ? (
              <div className="d-flex align-items-center">
                <p className="fs-m m<p>r-3 green">Added to cart</p>
                <i className="fas fa-check fs-m green"></i>
              </div>
            ) : (
              <>
                <div className="d-flex flex-wrap align-items-center mb-2">
                  <p className="price mr-5">
                    <p>MSRP :</p> $<span>{msrpPrice(data)}</span>
                  </p>
                  <p className="price mr-5">
                    <p>My Price :</p> $<span>{myPrice(data)}</span>
                  </p>
                  <p className={stock.stock > 0 ? "in-stock" : "out-of-stock"}>
                    {stock.stock > 0 ? (
                      <p>
                        In Stock : <span>{stock.stock}</span>
                      </p>
                    ) : (
                      <p>Out of stock</p>
                    )}
                  </p>
                </div>

                {stock.stock > 0 && (
                  <div className="d-flex mt-2 mb-2">
                    <ButtonSecondary
                      onClick={addProductToCart}
                      text="Add to Cart"
                      icon="fas fa-cart-plus"
                      customStyle={{ marginRight: "5rem" }}
                    />
                    <ButtonQuantity
                      qty={quantity}
                      onIncrease={() => {
                        quantity < stock.stock && setQuantity(+quantity + 1);
                      }}
                      onDecrease={() => {
                        quantity > 1 && setQuantity(+quantity - 1);
                      }}
                      onChange={(e) => {
                        e.target.value <= stock.stock &&
                          noSpaceOnChange(e.target.value) &&
                          numberValidation(e.target.value) &&
                          setQuantity(+e.target.value);
                      }}
                      onBlur={() => {
                        if (quantity <= 0 || quantity === null || quantity === undefined) {
                          setQuantity(1);
                        }
                      }}
                    />
                  </div>
                )}
              </>
            ))}
          <div className="d-flex align-items-center flex-wrap">
            <ButtonPrimary
              onClick={() => {
                setActiveBar("inquire");
                inquireRef.current.scrollIntoView();
                // localStorage.setItem("product", JSON.stringify({ mpn: data.sku, name: data.title }));
                // window.open(`${process.env.PUBLIC_URL}/inquireproduct`, "_blank");
              }}
              text="Inquire Product"
              icon="fas fa-question-circle"
              customClass="mr-5"
            />
            {data.hd4k && (
              <LazyLoadImg
                image={{
                  src: fourK,
                  alt: "4k-logo",
                  width: 50,
                  height: 50,
                  className: "fourk-logo",
                }}
              />
            )}
            <LazyLoadImg
              image={{
                src: warrantyLogo,
                alt: "warranty-logo",
                width: 30,
                height: 30,
                className: "warrantyLogo",
              }}
            />
          </div>
        </div>
      </div>
      {imgView && (
        <ImageView
          images={[imgDisplay, data.image1, data.image2, data.image3]}
          initialImg={imgDisplay}
          onClose={() => setImgView(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    cart: state.cart.cart ? state.cart.cart : [],
  };
};

export default connect(mapStateToProps, { addToCart })(ProductDisplay);
