import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { postNewsletter, formStatusPending, formStatusNull, formStatusCustom } from "../../actions/forms";
import axios from "../../api";
import "./styles.scss";

// Common Header Components
import MainMenu from "./partials/main-menu";
import InputSearchPrimary from "../../ui/input/InputSearchPrimary";
import LoginModal from "../features/modal/login-modal";

import { showModal, closeModal } from "../../actions";
import { setActiveMenu } from "../../actions/styles";
import Newsletter from "./Newsletter";
import FeedbackPrimary from "../../ui/feedback/FeedbackPrimary";

function Header(props) {
  const { container = "container", showModal, closeModal } = props;
  const { setActiveMenu, auth, cart } = props;
  const { postNewsletter, formStatusPending, formStatusNull, formStatus, formStatusCustom } = props;
  const { history } = props;
  let timer;
  const [displayNewsletter, setDisplayNewsletter] = useState(false);
  const [search, setSearch] = useState("");
  const [email, setEmail] = useState("");

  function openLoginModal(e) {
    showModal("login");
    e.preventDefault();
  }

  function closeLoginModal() {
    document.getElementById("login-modal").classList.remove("ReactModal__Content--after-open");

    timer = setTimeout(() => {
      closeModal("login");
    }, 200);
  }
  useEffect(() => {
    formStatusNull();
  }, []);

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  });

  useEffect(() => {
    const openNewsLetter = () => {
      const isLastTime = localStorage.getItem("endNewsletter");
      if (!isLastTime) {
        setDisplayNewsletter(true);
      }
      localStorage.setItem("endNewsletter", true);
    };
    const timeout = setTimeout(openNewsLetter, 15000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onSearch = (e, allowClick) => {
    if (search !== "") {
      if (e.key === "Enter" || allowClick) {
        history.push(`/search/${search}/1`);
      }
    }
  };

  const onSubmitNewsletter = async (e) => {
    e.preventDefault();
    formStatusPending();
    try {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, { action: "submit" });

        const res = await axios.get("/api/Home/ValidateCaptcha", {
          params: {
            captcha: token,
          },
        });
        if (res.data) {
          setEmail("");
          await postNewsletter({ email: email });
          setTimeout(() => {
            formStatusNull();
            setDisplayNewsletter(false);
          }, 3000);
        } else {
          formStatusCustom(404);
          setTimeout(() => {
            formStatusNull();
            setDisplayNewsletter(false);
          }, 3000);
        }
      });
    } catch (error) {
      formStatusCustom(404);
      setTimeout(() => {
        formStatusNull();
      }, 3000);
    }
  };

  return (
    <header className="header header-6">
      {/* {displayNewsletter && (
        <Newsletter
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onSubmit={onSubmitNewsletter}
          onClose={() => setDisplayNewsletter(false)}
        />
      )} */}
      {formStatus && <FeedbackPrimary formStatus={formStatus} />}
      <div className="header-top">
        <div className={container} style={{ height: 30 }}>
          <Link to={`${process.env.PUBLIC_URL}`} className="d-lg-none">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/home/kpro_header.png`}
              alt="kanex Pro"
              width={125}
              height={30}
            />
          </Link>
          <div className="header-left">
            <ul className="top-menu top-link-menu d-none d-md-block">
              <li>
                <Link to="#">Links</Link>
                <ul>
                  <li>
                    <a href="tel:8889751368">
                      <i className="icon-phone"></i>Sales & Support: (888) 975-1368
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="header-right">
            <div className="social-icons social-icons-color">
              <a
                href="https://www.facebook.com/kanexpro"
                className="social-icon social-facebook"
                rel="noopener noreferrer"
                title="Facebook"
                target="_blank"
              >
                <i className="icon-facebook-f"></i>
                <p>Facebook</p>
              </a>

              <a
                href="https://twitter.com/kanexpro"
                className="social-icon social-twitter"
                rel="noopener noreferrer"
                title="Twitter"
                target="_blank"
              >
                <i className="icon-twitter"></i>
                <p>Twitter</p>
              </a>
              <a
                href="https://www.linkedin.com/company/kanexpro?trk=biz-companies-cym"
                className="social-icon social-pinterest"
                rel="noopener noreferrer"
                title="Linkedin"
                target="_blank"
              >
                <i className="icon-linkedin"></i>
                <p>Linkedin</p>
              </a>
              <Link
                to="/contact"
                className="social-icon social-instagram"
                rel="noopener noreferrer"
                title="Contact"
                target="_blank"
              >
                <i className="fas fa-envelope"></i>
                <p>Contact</p>
              </Link>

              {auth.isAuthenticated && (
                <>
                  <Link to="/secure/cart" className="social-icon social-instagram">
                    <i className="fas fa-shopping-cart"></i>
                    {cart.length !== 0 && <div className="cart-indicator">{cart.length}</div>}
                    <p>Cart</p>
                  </Link>
                  <Link to="/account/info" className="social-icon social-instagram">
                    <i className="fas fa-user-circle"></i>
                    <p>Profile</p>
                  </Link>
                </>
              )}
            </div>
            <ul className="top-menu top-link-menu">
              <li>
                <Link to="#">Links</Link>
                <ul className="ml-4">
                  {!auth.isAuthenticated && (
                    <li>
                      <Link to="#signin-modal" data-toggle="modal" onClick={openLoginModal}>
                        <i className="icon-user"></i>
                        Login
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="header-bottom sticky-header">
        <div className={`${container} d-flex justify-content-lg-around`}>
          <Link to={`${process.env.PUBLIC_URL}`} className="d-none d-lg-block d-xl-block">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/home/KanexPro_Logo_Wht.svg`}
              alt="kanex Pro"
              width={200}
              height={30}
              // onClick={() => setActiveMenu("")}
            />
          </Link>
          <div className="header-left">
            <button className="mobile-menu-toggler">
              <span className="sr-only">Toggle mobile menu</span>
              <i className="icon-bars"></i>
            </button>
            <MainMenu location={props.location} />
          </div>
          <InputSearchPrimary
            customClass="d-none d-lg-inline-block d-xl-inline-block"
            onSearch={onSearch}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <LoginModal history={history} />
    </header>
  );
}

function mapStateToProps(state) {
  return {
    isWishlist: state.wishlist.list,
    auth: state.auth,
    cart: state.cart.cart ? state.cart.cart : [],
    formStatus: state.data.formStatus,
  };
}

export default connect(mapStateToProps, {
  showModal,
  closeModal,
  setActiveMenu,
  postNewsletter,
  formStatusPending,
  formStatusNull,
  formStatusCustom,
})(Header);
