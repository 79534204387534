import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import CardPrimary from "../../ui/CardPrimary";
import "./products.scss";
import Sidebar from "./Sidebar";
import BreadCrumbPrimary from "../../ui/breadcrumbs/BreadCrumbPrimary";
import { connect } from "react-redux";
import { getProductsMenu, getProducts, setLoadingTrue } from "../../actions/products";
import { products } from "../common/partials/navData";
import { productsData } from "./productsData";
import PaginationSecondary from "../../ui/pagination/PaginationSecondary";
import LoaderPrimary from "../../ui/loader/LoaderPrimary";

const canonicalTags = {
  "cables/aoc": "https://kanexpro.com/cables/aoc",
  "cables/hdmi": "https://kanexpro.com/cables/hdmi",
  "cables/hdmicertified": "https://kanexpro.com/cables/hdmi-certified",
  "converters/component": "https://kanexpro.com/converters/component",
  "extenders/hdmipro": "https://kanexpro.com/extenders/hdmi-pro",
  "extenders/wallplates": "https://kanexpro.com/extenders/wall-plates",
  "sdi/converters": "https://kanexpro.com/sdi/converters",
  "sdi/splitters": "https://kanexpro.com/sdi/splitters",
  "splitters/hdmi": "https://kanexpro.com/splitters/hdmi",
  "switchers/hdmi": "https://kanexpro.com/switchers/HDMI",
  "switchers/matrixmodular": "https://kanexpro.com/switchers/matrix-modular",
  "switchers/matrixmodules": "https://kanexpro.com/switchers/matrix-modules",
  "switchers/scalers": "https://kanexpro.com/switchers/scaler",

};

const SubCategory = (props) => {
  const { subCategory } = props.match.params; // value -> subcategory
  const { pathname } = props.location; // value -> /category/subcategory/  (last / is just when user navigates via side menu)
  const item = subCategory.charAt(0).toUpperCase() + subCategory.slice(1); // value -> Subcategory

  const menuFor = pathname.substring(1).replace(`/${subCategory}`, ""); // value -> category OR category/
  const menu = menuFor.includes("/") ? menuFor.slice(0, -1) : menuFor; // value  -> category

  const removedHyphenFromSubCategory = subCategory.includes("-") ? subCategory.replace("-", " ") : subCategory;
  const removeDecimalFromSubCategory = removedHyphenFromSubCategory.includes(".")
    ? removedHyphenFromSubCategory.replace(".", "")
    : removedHyphenFromSubCategory;

  const removeSpaceFromSubCategory = removeDecimalFromSubCategory.includes(" ")
    ? removeDecimalFromSubCategory.replace(" ", "")
    : removeDecimalFromSubCategory;

  const subCategoryToLowerCase = removeSpaceFromSubCategory.toLowerCase();
  useEffect(() => {
    props.productsMenu.length === 0 && props.getProductsMenu();
    props.getProducts(menu, removedHyphenFromSubCategory);

    return () => {
      props.setLoadingTrue();
    };
  }, [subCategory]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = props.subCategoryProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const canonicalUrl = canonicalTags[`${menu}/${subCategoryToLowerCase}`];

  if (props.loading) {
    return <LoaderPrimary />;
  }

  return (
    <>
      <Helmet>
        <title>{productsData[menu][subCategoryToLowerCase].title}</title>
        <meta name="description" content={productsData[menu][subCategoryToLowerCase].description}></meta>
        <meta name="keywords" content={productsData[menu][subCategoryToLowerCase].keywords}></meta>
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      </Helmet>
      <div>
        <div className="subcategory">
          <div className="d-none d-lg-block d-xl-block">
            {/* Passing routes to and data to sidebar for styling and interactive purposes */}
            <Sidebar path={`/${menu}`} subPath={pathname} data={products} />
          </div>
          <div className="products-container">
            <BreadCrumbPrimary
              home="home"
              linkHome="/"
              category={menu}
              linkCategory={`/${menu}`}
              subCategory={subCategory}
            />
            <h1 className="display-4 mb-3">{productsData[menu][subCategoryToLowerCase].name}</h1>
            <div className="card-container">
              {currentProducts.map((product) => (
                <CardPrimary
                  key={product.pkskuid}
                  image={product.kproimage}
                  title={product.title}
                  subtitle={product.kprodesc}
                  mpn={product.sku}
                  link={`/item/${product.sku}`}
                />
              ))}
            </div>
            <PaginationSecondary
              productsPerPage={productsPerPage}
              totalProducts={props.subCategoryProducts.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subCategoryProducts: state.data.subCategoryProducts ? state.data.subCategoryProducts : [],
    productsMenu: state.data.productsMenu ? state.data.productsMenu : [],
    loading: state.data.loading,
  };
};

export default connect(mapStateToProps, { getProductsMenu, getProducts, setLoadingTrue })(SubCategory);
