import React from "react";
import { Link } from "react-router-dom";
import "./button.scss";

const ButtonPrimary = ({ link, text, icon, customClass, customStyle, onClick }) => {
  if (link) {
    return (
      <Link
        onClick={onClick ? onClick : null}
        className={`btn btn-primary ${customClass}`}
        style={customStyle}
        to={link ? link : "#"}
      >
        {icon && <i className={icon}></i>}
        {text}
      </Link>
    );
  } else {
    return (
      <button onClick={onClick} className={`btn btn-primary ${customClass}`} style={customStyle}>
        {icon && <i className={icon}></i>}
        {text}
      </button>
    );
  }
};

export default ButtonPrimary;
