import React, { useRef } from "react";
import ListFreePrimary from "../../../ui/list/ListFreePrimary";
import { account } from "../../common/partials/navData";
import SidebarSecondary from "../../common/sidebar/SidebarSecondary";
import "./review-order.scss";
import { Link } from "react-router-dom";
import InputSearchSecondary from "../../../ui/input/InputSearchSecondary";
import ButtonPrimary from "../../../ui/button/ButtonPrimary";
import ButtonSecondary from "../../../ui/button/ButtonSecondary";
import { connect } from "react-redux";

const ReviewOrder = ({ location, history, auth }) => {
  if (!auth.isAuthenticated) {
    history.push("/");
  }

  const scrollRef = useRef();
  const scrollTable = (direction, tableScrollRef) => {
    if (direction === "left") {
      tableScrollRef.current.scrollLeft -= 100;
    }
    if (direction === "right") {
      tableScrollRef.current.scrollLeft += 100;
    }
  };

  return (
    <div className="row">
      <div className="col-lg-3 d-none d-lg-block d-xl-block">
        <SidebarSecondary path={location.pathname} data={account} title="Account" />
      </div>
      <div className="col-lg-9">
        <div className="review-order">
          <h1 className="display-4">Order Details</h1>

          <ListFreePrimary customClass="background-grey mb-3">
            <p className="lead fs-m">Items</p>
            <hr />
            <InputSearchSecondary customClass="mb-2" />
            <span>
              <span className="scroll-btn" onClick={() => scrollTable("left", scrollRef)}>
                ❮
              </span>{" "}
              Scroll{" "}
              <span className="scroll-btn" onClick={() => scrollTable("right", scrollRef)}>
                ❯
              </span>
            </span>

            <div ref={scrollRef} className="scroll-x">
              <table className="table">
                <thead className="row-brown">
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col">MPN</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Price</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Descriptiion</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                    <td>test</td>
                    <td>@mdo</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ListFreePrimary>
          <div className="bill-ship-container mb-3">
            <ListFreePrimary customClass="bill">
              <p className="lead fs-m">Billing Information</p>
              <hr />

              <p>
                <b>Address: </b>Duis ut tincidunt metus. Duis cursus mollis posuere. Maecenas a dolor non nisl mollis
                luctus et in mauris.
              </p>
              <p>
                <b>City: </b>New York City
              </p>
              <p className="mb-1">
                <b>State: </b>NY
              </p>
              <ButtonPrimary link="/account/info" text="Change" />
            </ListFreePrimary>
            <ListFreePrimary customClass="background-grey ship">
              <p className="lead fs-m">Shipping Information</p>
              <hr />
              <p>
                <b>Address: </b>Duis ut tincidunt metus. Duis cursus mollis posuere. Maecenas a dolor non nisl mollis
                luctus et in mauris.
              </p>
              <p>
                <b>City: </b>New York City
              </p>
              <p className="mb-1">
                <b>State: </b>NY
              </p>
              <ButtonSecondary link="/account/info" text="Change" />
            </ListFreePrimary>
          </div>
          <ListFreePrimary customClass="mb-3">
            <p className="lead fs-m">Order Notes (PO, Shipping Method etc.):</p>
            <hr className="mb-2" />
            <div className="form-group">
              <textarea className="form-control" id="inputTextarea" rows="3" placeholder="Comment here..."></textarea>
            </div>
          </ListFreePrimary>
          <p className="lead text-center">
            We will always contact you with a phone call to confirm order before processing.
          </p>
          <div className="d-flex justify-content-around mt-3">
            <button className="btn btn-primary">Revise Order</button>
            <button size={1} type="submit" className="btn btn-secondary">
              Submit Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ReviewOrder);
