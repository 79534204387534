import React, { useState } from "react";
import Products from "./Products";

const SuggestedProducts = (props) => {
  const { converters, extenders, splitters, switchers } = props;

  const initialTab =
    converters.length !== 0
      ? "converters"
      : extenders.length !== 0
      ? "extenders"
      : splitters.length !== 0
      ? "splitters"
      : "switchers";

  const [activeBar, setActiveBar] = useState(initialTab);
  return (
    <div className="mt-3">
      <ul className="nav nav-tabs">
        {converters.length !== 0 && (
          <li className="nav-item">
            <div
              className={`nav-link ${activeBar === "converters" ? "active" : "notactive"}`}
              onClick={() => setActiveBar("converters")}
            >
              Converters
            </div>
          </li>
        )}

        {extenders.length !== 0 && (
          <li className="nav-item">
            <div
              className={`nav-link ${activeBar === "extenders" ? "active" : "notactive"}`}
              onClick={() => setActiveBar("extenders")}
            >
              Extenders
            </div>
          </li>
        )}

        {splitters.length !== 0 && (
          <li className="nav-item">
            <div
              className={`nav-link ${activeBar === "splitters" ? "active" : "notactive"}`}
              onClick={() => setActiveBar("splitters")}
            >
              Splitters
            </div>
          </li>
        )}

        {switchers.length !== 0 && (
          <li className="nav-item">
            <div
              className={`nav-link ${activeBar === "switchers" ? "active" : "notactive"}`}
              onClick={() => setActiveBar("switchers")}
            >
              Switchers
            </div>
          </li>
        )}

        {/* <li className="nav-item">
          <div
            className={`nav-link ${activeBar === "panel" ? "active" : "notactive"}`}
            onClick={() => setActiveBar("panel")}
          >
            Panel
          </div>
        </li>

        <li className="nav-item">
          <div
            className={`nav-link ${activeBar === "downloads" ? "active" : "notactive"}`}
            onClick={() => setActiveBar("downloads")}
          >
            Downloads
          </div>
        </li> */}
      </ul>

      <div className="tab-content mt-3">
        {activeBar === "converters" && converters.length !== 0 && <Products data={converters} />}

        {activeBar === "extenders" && extenders.length !== 0 && <Products data={extenders} />}

        {activeBar === "splitters" && splitters.length !== 0 && <Products data={splitters} />}

        {activeBar === "switchers" && switchers.length !== 0 && <Products data={switchers} />}

        {/* {activeBar === "panel" && null}

        {activeBar === "downloads" && null} */}
      </div>
    </div>
  );
};

export default SuggestedProducts;
