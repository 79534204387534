import React from "react";
import { Helmet } from "react-helmet";
import SidebarSecondary from "../common/sidebar/SidebarSecondary";
import FlexMatrixPage from "./FlexMatrixPage";
import "./FlexMatrix.scss";
import { resource, sales } from "../common/partials/navData";
import banner from "../resources/images/banner.jpg";

export default function FlexMatrix({ location }) {
  return (
    <>
      <Helmet>
        <title>Flex Matrix</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
      </Helmet>
      <div className="d-flex">
        {/* <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={resource} title="Resources" />
        </div> */}
        <div className="warranty-container">
          {/* <div className="banner">
            <img src={banner} alt="banner" />
            <div className="bg-dark" /> */}

          {/* </div> */}
          <div className="flexMatrix">
            {/* <h1 className="display-4">Flex Matrix</h1> */}
            <FlexMatrixPage />
          </div>
        </div>
      </div>
    </>
  );
}
