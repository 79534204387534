export const cardNumberValidation = (number, company) => {
  if (!company) {
    return false;
  }
  if (company === "American Express") {
    return (number.substring(0, 2) === "34" || number.substring(0, 2) === "37") && number.length === 15 ? true : false;
  }
  if (company === "Visa") {
    return number.substring(0, 1) === "4" && (number.length === 13 || number.length === 16) ? true : false;
  }
  if (company === "MasterCard") {
    return number.substring(0, 2) >= "51" && number.substring(0, 2) <= 55 && number.length === 16 ? true : false;
  }
};

export const cardMonthValidation = (month) => {
  if (!month || Number(month) === 0 || month.length < 2) {
    return false;
  }
  return true;
};

export const cardYearValidation = (year) => {
  if (!year || Number(year) === 0 || Number(year) < new Date().getFullYear()) {
    return false;
  }
  return true;
};

export const cardCvvValidation = (cvv) => {
  if (!cvv || cvv.length < 3) {
    return false;
  }
  return true;
};
