import React from "react";
import { Helmet } from "react-helmet";
import { about } from "../common/partials/navData";
import SidebarSecondary from "../common/sidebar/SidebarSecondary";
import Aboutus from "./Aboutus";
import "./Aboutus.scss";
import banner from "./Aboutus_img/banner.svg";

export default function About({ location }) {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="Professional Audio Video components"></meta>
        <meta name="keywords" content="kanexpro"></meta>
        <link rel="canonical" href="https://kanexpro.com/company"></link>
      </Helmet>
      <div className="d-flex">
        <div className="d-none d-lg-block d-xl-block">
          <SidebarSecondary path={location.pathname} data={about} title="About" />
        </div>
        <div>
          {/* <img className="about-us-banner" src={banner} alt="banner" /> */}
          <div className="about-us">
            <h1 className="display-4">About Us</h1>
            <Aboutus />
          </div>
        </div>
      </div>
    </>
  );
}
