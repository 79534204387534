import React from "react";
import "./card-tertiary.scss";
import { Link } from "react-router-dom";

const CardTertiary = ({ src, text, customClass, link, onClick }) => {
  return (
    <Link className={`card-tertiary ${customClass}`} to={link ? link : "#"} onClick={onClick}>
      <div className="image-container">
        <img src={src} alt="category" className="mb-1" />
        <div className="image-dark">
          <p>Read More</p>
        </div>
      </div>
      <div className="text-container">
        <p className="mb-2">{text}</p>
      </div>
    </Link>
  );
};

export default CardTertiary;
