import React from "react";
import "./feedback-primary.scss";

const FeedbackPrimary = ({ formStatus }) => {
  return (
    <div className="feedback-primary">
      {formStatus === 200 ? (
        <div className="message" style={{ color: "#00FF00" }}>
          <i className="fas fa-check-circle"></i>
          <p>Submitted Successfully</p>
        </div>
      ) : formStatus !== 200 && formStatus !== null && formStatus !== "pending" ? (
        <div className="message" style={{ color: "#FF0000" }}>
          <i className="fas fa-times-circle"></i>
          <p>Something went wrong</p>
        </div>
      ) : null}
      {formStatus === "pending" && <div className="loading" />}
    </div>
  );
};

export default FeedbackPrimary;
