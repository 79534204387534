import React from "react";
import "./pagination-secondary.scss";

const PaginationSecondary = ({ productsPerPage, totalProducts, paginate, currentPage }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++) {
    pageNumbers.push(i);
  }
  if (pageNumbers.length <= 1) {
    return null;
  }
  return (
    <div className="pagination-secondary">
      {pageNumbers.map((number) => (
        <div key={number} onClick={() => paginate(number)} className={currentPage === number ? "active" : ""}>
          <p>{number}</p>
        </div>
      ))}
    </div>
  );
};

export default PaginationSecondary;
