import React from "react";
import "./loader-primary.scss";

const LoaderPrimary = () => {
  return (
    <div className="loader-bar">
      <div className="bar" />
    </div>
  );
};

export default LoaderPrimary;
