import React, { useState } from "react";
import { connect } from "react-redux";
import { emailValidation, noSpaceOnChange, notEmptyValidation } from "../../../validation/formValidation";
import axios from "../../../api";
import { postFaq, formStatusNull, formStatusCustom, formStatusPending } from "../../../actions/forms";
import FormInput from "../FormInput";
import FormTextarea from "../FormTextarea";

const FaqForm = ({ data, postFaq, formStatus, formStatusNull, formStatusCustom, formStatusPending }) => {
  const [faqData, setFaqData] = useState({
    name: "",
    email: "",
    question: "",
    isNewsletter: false,
  });

  const [faqError, setFaqError] = useState({
    nameError: "",
    emailError: "",
    questionError: "",
  });

  const onChangeData = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      noSpaceOnChange(value) && setFaqData({ ...faqData, [name]: value });
    } else if (name === "isNewsletter") {
      setFaqData({ ...faqData, isNewsletter: !faqData.isNewsletter });
    } else {
      setFaqData({ ...faqData, [name]: value });
    }
  };

  const onBlurInput = (e) => {
    const { name } = e.target;

    switch (name) {
      case "name":
        if (!notEmptyValidation(faqData.name)) {
          setFaqError({ ...faqError, nameError: "Please enter your name" });
        } else {
          setFaqError({ ...faqError, nameError: "" });
        }
        break;

      case "email":
        if (!emailValidation(faqData.email)) {
          setFaqError({ ...faqError, emailError: "Please enter a valid email" });
        } else {
          setFaqError({ ...faqError, emailError: "" });
        }
        break;

      case "question":
        if (!notEmptyValidation(faqData.question)) {
          setFaqError({ ...faqError, questionError: "Please enter your question" });
        } else {
          setFaqError({ ...faqError, questionError: "" });
        }
        break;

      default:
        break;
    }
  };

  const onSubmitValidation = () => {
    return (
      notEmptyValidation(faqData.name) &&
      emailValidation(faqData.email) &&
      notEmptyValidation(faqData.question)
    );
  };

  const onSubmitFaq = async (e) => {
    e.preventDefault();
    if (!onSubmitValidation()) return;

    formStatusPending();

    const body = {
      question: faqData.question,
      answer: faqData.question,
      fkpid: 0,
      email: faqData.email,
      name: faqData.name,
      sku: data.sku,
      kpro: true,
    };

    try {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, { action: "submit" });

        const res = await axios.get("/api/Home/ValidateCaptcha", {
          params: {
            captcha: token,
          },
        });

        if (res.data) {
          setFaqData({
            name: "",
            email: "",
            question: "",
            isNewsletter: false,
          });
          setFaqError({
            nameError: "",
            emailError: "",
            questionError: "",
          });
          await postFaq(body);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        } else {
          formStatusCustom(404);
          setTimeout(() => {
            formStatusNull();
          }, 3000);
        }
      });
    } catch (error) {
      formStatusCustom(404);
      setTimeout(() => {
        formStatusNull();
      }, 3000);
    }
  };

  return (
    <form className="faq-form flex flex-col items-center gap-10" onSubmit={onSubmitFaq}>
      <h1 className="display-center-5 text-center">Ask your question</h1>
      <div className="flex flex-col gap-10 p-20 w-full max-w-screen-lg bg-white rounded-md hover:shadow-xl transition-all ease-in-out">
        <FormInput
          title="Name"
          type="text"
          name="name"
          value={faqData.name}
          onChange={onChangeData}
          onBlur={onBlurInput}
          placeholder="Enter your name"
          required
          error={faqError.nameError}
        />
        <FormInput
          title="Email"
          type="text"
          name="email"
          value={faqData.email}
          onChange={onChangeData}
          onBlur={onBlurInput}
          placeholder="Enter your email"
          required
          error={faqError.emailError}
        />
        <FormTextarea
          title="Question"
          placeholder="Type your question here..."
          name="question"
          value={faqData.question}
          onChange={onChangeData}
          onBlur={onBlurInput}
          required
          error={faqError.questionError}
        />
        <div className="form-check text-left">
          <input
            className="form-check-input"
            type="checkbox"
            id="inputCheckbox"
            name="isNewsletter"
            checked={faqData.isNewsletter}
            onChange={onChangeData}
          />
          <label className="form-check-label ml-4" htmlFor="inputCheckbox">
            Yes, please send me latest information on KanexPro
          </label>
        </div>
        <button className="btn btn-primary float-xs-right" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    formStatus: state.data.formStatus,
  };
};

export default connect(mapStateToProps, { postFaq, formStatusNull, formStatusCustom, formStatusPending })(FaqForm);
