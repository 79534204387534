import React from 'react'
import Work from './Work';
import { Helmet } from 'react-helmet';


export default function WorkFromHome() {
    return (
        <div>
            <Helmet>
                <link rel="canonical" href="https://kanexpro.com/Work_From_Home" />
            </Helmet>
            <Work />
        </div>
    )
}
